export const fund = [
    {"资金安排年度":"2021","市级":"滨州市","项目编号":"20-4-15-3700-226","项目名称":"阳信县西北村古（战国）墓进行考古调查勘探","保护单位名称":"西北村古（战国）墓","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"60","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"滨州市","项目编号":"20-4-15-3700-123","项目名称":"滨州市阳信县豆腐店遗址考古勘探项目","保护单位名称":"豆腐店遗址","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"60","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"滨州市","项目编号":"20-4-15-3700-077","项目名称":"滨州市邹平临池古城（於陵城）遗址考古勘探项目","保护单位名称":"邹平临池古城","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"300","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"滨州市","项目编号":"20-5-13-3700-116","项目名称":"山东阳信县西北村战国墓出土文物保护修复项目","保护单位名称":"阳信县文物保护中心","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"50","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"滨州市","项目编号":"20-5-13-3700-019","项目名称":"邹平博物馆馆藏青铜文物保护修复项目","保护单位名称":"邹平市博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"100","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"滨州市","项目编号":"19-5-12-3700-253","项目名称":"无棣县文物管理局可移动文物 预防性保护项目","保护单位名称":"无棣县文物管理局","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"100","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"滨州市","项目编号":"20-5-12-3700-023","项目名称":"滨州市沾化区博物馆馆藏文物预防性保护","保护单位名称":"沾化区博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"180","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"德州市","项目编号":"21-4-15-3700-024","项目名称":"唐代乐陵县县城遗址考古勘探项目","保护单位名称":"唐代乐陵县县城遗址","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"160","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"德州市","项目编号":"20-5-13-3700-045","项目名称":"山东德州市博物馆馆藏陶瓷文物保护修复","保护单位名称":"德州市博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"160","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"德州市","项目编号":"20-5-13-3700-046","项目名称":"山东德州市博物馆馆藏书画保护修复","保护单位名称":"德州市博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"159","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"德州市","项目编号":"20-5-12-3700-057","项目名称":"山东冀鲁边革命纪念馆可移动文物预防性保护","保护单位名称":"冀鲁边区革命纪念馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"130","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"德州市","项目编号":"20-7-02-3700-220","项目名称":"黄河涯礼堂修缮保护工程","保护单位名称":"黄河涯礼堂","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"29","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"德州市","项目编号":"20-7-02-3700-132","项目名称":"乐陵故城西城墙、北城墙保护工程","保护单位名称":"乐陵故城","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"85","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"德州市","项目编号":"20-7-02-3700-115","项目名称":"八路军东进抗日挺进纵队政治部旧址修缮工程","保护单位名称":"八路军东进抗日挺进纵队政治部","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"40","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"德州市","项目编号":"20-7-02-3700-238","项目名称":"陵城区徽王石桥维修保护工程","保护单位名称":"徽王石桥","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"72","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"德州市","项目编号":"20-7-02-3700-150","项目名称":"禹城前寺古庙保护维修工程","保护单位名称":"前寺古庙","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"50","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"德州市","项目编号":"20-7-02-3700-110","项目名称":"“铁帽子五连”连部旧址修缮工程","保护单位名称":"“铁帽子五连”连部旧址","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"23","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"德州市","项目编号":"20-7-02-3700-228","项目名称":"乐陵市禹氏家祠“利奇马”灾后抢险维修工程","保护单位名称":"禹氏祠堂","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"68","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"东营市","项目编号":"19-5-12-3700-273","项目名称":"东营市垦利区博物馆文物预防性保护项目","保护单位名称":"垦利区博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"80","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"东营市","项目编号":"20-5-12-3700-036","项目名称":"东营市历史博物馆馆藏文物预防性保护项目（二期）","保护单位名称":"东营市历史博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"210","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"东营市","项目编号":"20-5-13-3700-098","项目名称":"渤海垦区革命纪念馆馆藏革命文物保护修复","保护单位名称":"垦利区博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"130","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"东营市","项目编号":"20-7-02-3700-188","项目名称":"广饶县寨村泉顺院保护维修工程","保护单位名称":"寨村泉顺院","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"80","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"东营市","项目编号":"20-7-02-3700-196","项目名称":"牛庄烈士祠-正厅及院落修缮保护工程","保护单位名称":"牛庄烈士祠","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"40","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"菏泽市","项目编号":"4-15-20-3700-009","项目名称":"2020年山东定陶王墓地（王陵）M2汉墓考古发掘项目","保护单位名称":"定陶王墓地（王陵）","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"2000","保护单位类型":"古墓葬","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"菏泽市","项目编号":"20-5-13-3700-085","项目名称":"菏泽市博物馆馆藏珍贵古籍保护修复项目","保护单位名称":"菏泽市博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"100","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"菏泽市","项目编号":"20-5-13-3700-119","项目名称":"山东菏泽市抗日纪念馆馆藏革命文物保护修复","保护单位名称":"菏泽市博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"100","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"菏泽市","项目编号":"19-5-12-3700-321","项目名称":"冀鲁豫边区革命纪念馆可移动文物预防性保护","保护单位名称":"冀鲁豫边区革命纪念馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"210","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"菏泽市","项目编号":"20-7-31-3700-207","项目名称":"华东野战军第八纵队战地医院旧址修缮工程","保护单位名称":"华东野战军第八纵队战地医院旧址","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"150","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"济南市","项目编号":"20-5-13-3700-024","项目名称":"平阴县博物馆馆藏金属文物保护修复项目","保护单位名称":"平阴县博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"50","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"济南市","项目编号":"20-5-12-3700-037","项目名称":"济南市章丘区博物馆馆藏文物预防性保护项目","保护单位名称":"章丘市博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"240","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"济南市","项目编号":"21-1-02-3700-051","项目名称":"齐长城长清区定头崖西山段保护展示项目","保护单位名称":"长城——齐长城遗址","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"600","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"济南市","项目编号":"20-1-02-3700-233","项目名称":"济南洪家楼天主教堂屋面修缮保护","保护单位名称":"洪家楼天主教堂","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"420","保护单位类型":"近现代","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"济南市","项目编号":"20-7-31-3700-122","项目名称":"棋山观抗日阵亡烈士纪念碑保护展示工程","保护单位名称":"棋山观抗日阵亡烈士纪念碑","补助范围":"省级及省级以下文物保护单位保护","补助事项":"革命文物保护利用片区整体陈列展示","安排金额（万元）":"150","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"济南市","项目编号":"19-7-02-3700-159","项目名称":"济南五峰山洞真观古建筑群修缮保护工程","保护单位名称":"五峰山洞真观","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"220","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"济南市","项目编号":"19-7-02-3700-165","项目名称":"小娄峪古建筑群修缮保护工程","保护单位名称":"小娄峪古建筑群","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"50","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"济南市","项目编号":"20-7-02-3700-113","项目名称":"济南市历城区三清殿修缮保护工程","保护单位名称":"三清殿","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"70","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"济南市","项目编号":"20-7-02-3700-149","项目名称":"小官庄汉墓群M6保护性设施建设工程","保护单位名称":"小官庄墓群","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"30","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"济宁市","项目编号":"21-4-15-3700-010","项目名称":"琅琊台遗址考古发掘","保护单位名称":"琅琊台遗址","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"108","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2021","市级":"济宁市","项目编号":"20-5-13-3700-027","项目名称":"嘉祥县旅游文物服务中心馆藏金属器文物保护修复","保护单位名称":"嘉祥县旅游文物服务中心","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"120","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"济宁市","项目编号":"5-14-20-3700-002","项目名称":"孔子博物馆珍贵文物数字化保护项目","保护单位名称":"孔子博物馆","补助范围":"可移动文物保护","补助事项":"数字化保护","安排金额（万元）":"1843","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2021","市级":"济宁市","项目编号":"20-1-02-3700-231","项目名称":"铁山摩崖石刻保护前期勘察研究","保护单位名称":"铁山、岗山摩崖石刻","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"190","保护单位类型":"石窟寺及石刻","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"济宁市","项目编号":"20-1-02-3700-224","项目名称":"明鲁王墓荒王陵祾恩门、享殿保护修缮项目","保护单位名称":"明鲁王墓","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"130","保护单位类型":"古墓葬","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"济宁市","项目编号":"20-1-02-3700-028","项目名称":"嘉祥武氏墓群石刻前期勘察研究","保护单位名称":"嘉祥武氏墓群石刻","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"200","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"济宁市","项目编号":"20-1-02-3700-232","项目名称":"孟庙-泰山气象门、承圣门、亚圣殿、启圣殿彩画保护前期勘察研究","保护单位名称":"孟庙、孟府和孟林（孟庙及孟府）","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"120","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"济宁市","项目编号":"20-1-03-3700-137","项目名称":"曲阜师范学校旧址安防工程","保护单位名称":"曲阜师范学校旧址","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"75","保护单位类型":"近现代","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"济宁市","项目编号":"20-1-05-3700-146","项目名称":"曲阜师范学校旧址消防工程","保护单位名称":"曲阜师范学校旧址","补助范围":"全国重点文物保护单位保护","补助事项":"消防","安排金额（万元）":"320","保护单位类型":"近现代","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"济宁市","项目编号":"20-7-02-3700-083","项目名称":"济宁市太白楼修缮保护工程","保护单位名称":"济宁太白楼","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"60","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"济宁市","项目编号":"19-7-05-3700-412","项目名称":"泗水仲庙消防工程","保护单位名称":"泗水仲庙","补助范围":"省级及省级以下文物保护单位保护","补助事项":"消防","安排金额（万元）":"180","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"聊城市","项目编号":"4-15-21-3700-002","项目名称":"山东省菏泽市高新区青邱堌堆遗址考古发掘","保护单位名称":"青邱堌堆","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"304","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2021","市级":"聊城市","项目编号":"20-5-12-3700-223","项目名称":"聊城中国运河文化博物馆可移动文物预防性保护","保护单位名称":"聊城中国运河文化博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"200","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"聊城市","项目编号":"20-5-13-3700-017","项目名称":"孔繁森同志纪念馆馆藏革命文物保护修复项目","保护单位名称":"孔繁森同志纪念馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"110","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"聊城市","项目编号":"20-5-13-3700-021","项目名称":"聊城中国运河文化博物馆馆藏陶瓷器文物保护修复项目","保护单位名称":"聊城中国运河文化博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"150","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"聊城市","项目编号":"20-5-14-3700-043","项目名称":"临清市博物馆文物数字化保护项目","保护单位名称":"山东省临清市博物馆","补助范围":"可移动文物保护","补助事项":"数字化保护","安排金额（万元）":"80","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"聊城市","项目编号":"20-1-02-3700-234","项目名称":"聊城山陕会馆十一处建筑彩画修缮前期勘测","保护单位名称":"聊城山陕会馆","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"148","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"临沂市","项目编号":"20-1-02-3700-184","项目名称":"新四军军部暨华东军区、华东野战军诞生地修缮保护工程","保护单位名称":"新四军军部暨华东军区、华东野战军诞生地旧址","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"47","保护单位类型":"近现代","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"临沂市","项目编号":"20-1-14-3700-209","项目名称":"沂南北寨汉画像石墓博物馆 文物数字化保护与利用项目","保护单位名称":"北寨墓群","补助范围":"全国重点文物保护单位保护","补助事项":"数字化保护","安排金额（万元）":"59","保护单位类型":"古墓葬","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"临沂市","项目编号":"20-7-02-3700-225","项目名称":"王换于故居抢险加固工程","保护单位名称":"王换于故居","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"50","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"日照市","项目编号":"20-1-02-3700-181","项目名称":"两城镇遗址考古发掘现场保护展示工程","保护单位名称":"两城镇遗址","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"800","保护单位类型":"古遗址","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"泰安市","项目编号":"20-7-02-3700-200","项目名称":"泰安市岱岳区文姜城遗址考古调查勘探","保护单位名称":"文姜城遗址","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"180","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"泰安市","项目编号":"20-7-02-3700-206","项目名称":"泰安市燕语城遗址考古调查勘探工程","保护单位名称":"燕语城遗址","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"100","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"泰安市","项目编号":"20-4-15-3700-205","项目名称":"泰安市泰山区汉明堂遗址考古勘探工作","保护单位名称":"汉明堂遗址","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"80","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"泰安市","项目编号":"20-7-02-3700-213","项目名称":"肥城市精礼寺遗址考古调查勘探","保护单位名称":"精礼寺遗址","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"30","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"泰安市","项目编号":"21-4-15-3700-034","项目名称":"抬头寺遗址考古调查勘探工程","保护单位名称":"抬头寺遗址","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"50","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"泰安市","项目编号":"20-5-14-3700-148","项目名称":"新泰市博物馆可移动文物数字化保护利用项目","保护单位名称":"新泰市博物馆","补助范围":"可移动文物保护","补助事项":"数字化保护","安排金额（万元）":"150","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"泰安市","项目编号":"20-5-13-3700-032","项目名称":"山东省肥城市博物馆馆藏陶瓷器文物保护修复","保护单位名称":"肥城市文物管理所","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"80","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"泰安市","项目编号":"20-5-13-3700-144","项目名称":"泰安市博物馆馆藏陶瓷器保护修复项目","保护单位名称":"泰安市博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"150","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"泰安市","项目编号":"20-1-02-3700-227","项目名称":"泰山古建筑群-碧霞祠（二期）修缮工程","保护单位名称":"泰山古建筑群","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"600","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"泰安市","项目编号":"21-1-03-3700-006","项目名称":"陶山朝阳洞石刻造像安防工程","保护单位名称":"陶山朝阳洞石刻造像","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"80","保护单位类型":"石窟及石刻","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"泰安市","项目编号":"20-1-04-3700-216","项目名称":"泰山古建筑群—王母池防雷工程","保护单位名称":"泰山古建筑群","补助范围":"全国重点文物保护单位保护","补助事项":"防雷","安排金额（万元）":"120","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"泰安市","项目编号":"20-1-04-3700-217","项目名称":"泰山古建筑群—斗母宫防雷工程","保护单位名称":"泰山古建筑群","补助范围":"全国重点文物保护单位保护","补助事项":"防雷","安排金额（万元）":"150","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"泰安市","项目编号":"1-02-21-3700-003","项目名称":"潍县西方侨民集中营旧址保护修缮工程","保护单位名称":"潍县西方侨民集中营旧址","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"1200","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2021","市级":"泰安市","项目编号":"20-7-02-3700-129","项目名称":"泰安火车站站房修缮保护工程","保护单位名称":"泰安火车站站房","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"180","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"泰安市","项目编号":"20-7-02-3700-212","项目名称":"肥城市冯庄岱南书院修缮保护工程","保护单位名称":"冯庄岱南书院","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"70","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"泰安市","项目编号":"20-7-02-3700-210","项目名称":"肥城市潮泉镇塔山玉皇庙修缮保护工程","保护单位名称":"塔山玉皇庙","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"60","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"泰安市","项目编号":"20-7-02-3700-099","项目名称":"作书房遗址修缮保护工程","保护单位名称":"作书房遗址","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"110","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"泰安市","项目编号":"20-7-02-3700-204","项目名称":"泰安市岱岳区粥店崇兴寺—大殿修缮保护工程","保护单位名称":"粥店崇兴寺","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"55","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"泰安市","项目编号":"20-7-02-3700-087","项目名称":"泰安市中华圣公会教堂南配楼抢险加固工程","保护单位名称":"中华圣公会配楼","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"230","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"泰安市","项目编号":"20-7-02-3700-214","项目名称":"肥城市吕仙村古民居修缮保护工程","保护单位名称":"吕仙村古民居","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"200","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"威海市","项目编号":"4-15-21-3700-006","项目名称":"山东省济宁邹城市邾国故城遗址2021年度考古发掘","保护单位名称":"邾国故城","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"76","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2021","市级":"威海市","项目编号":"19-5-14-3700-314","项目名称":"荣成博物馆文物数字化保护项目","保护单位名称":"荣成博物馆","补助范围":"可移动文物保护","补助事项":"数字化保护","安排金额（万元）":"118","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"威海市","项目编号":"20-1-02-3700-001","项目名称":"刘公岛甲午战争纪念地——北洋海军基地后勤保障设施（工程局、机器局）修缮工程","保护单位名称":"刘公岛甲午战争纪念地","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"1000","保护单位类型":"近现代","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"威海市","项目编号":"20-1-02-3700-141","项目名称":"威海英式建筑—华勇营大楼维修保护工程","保护单位名称":"威海英式建筑","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"270","保护单位类型":"近现代","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"潍坊市","项目编号":"20-5-12-3700-020","项目名称":"昌乐县博物馆馆藏文物预防性保护","保护单位名称":"昌乐县博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"240","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"潍坊市","项目编号":"20-5-14-3700-025","项目名称":"山东省青州市博物馆馆藏文物数字化保护","保护单位名称":"青州市博物馆","补助范围":"可移动文物保护","补助事项":"数字化保护","安排金额（万元）":"100","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"潍坊市","项目编号":"19-5-14-3700-216","项目名称":"寿光市博物馆文物数字化保护项目","保护单位名称":"寿光市博物馆","补助范围":"可移动文物保护","补助事项":"数字化保护","安排金额（万元）":"70","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"潍坊市","项目编号":"20-5-12-3700-170","项目名称":"潍坊市博物馆馆藏文物预防性保护项目","保护单位名称":"潍坊市博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"330","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"潍坊市","项目编号":"5-14-21-3700-014","项目名称":"刘公岛甲午战争纪念地可移动文物数字化保护","保护单位名称":"中国甲午战争博物馆","补助范围":"可移动文物保护","补助事项":"数字化保护","安排金额（万元）":"125","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2021","市级":"潍坊市","项目编号":"20-1-05-3700-195","项目名称":"潍县西方侨民集中营旧址消防设施建设工程","保护单位名称":"潍县西方侨民集中营旧址","补助范围":"全国重点文物保护单位保护","补助事项":"消防","安排金额（万元）":"450","保护单位类型":"近现代","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"潍坊市","项目编号":"1-02-21-3700-011","项目名称":"孔庙三期西路古建筑油饰彩画保护维修工程(启圣门、启圣门东侧碑廊、乐器库、金丝堂、启圣王殿院门、启圣王殿、启圣王寝殿）","保护单位名称":"曲阜孔庙及孔府","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"1169","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2021","市级":"潍坊市","项目编号":"20-7-02-3700-156","项目名称":"潍坊昌邑北孟一村刘瀛海故居修缮保护工程","保护单位名称":"刘瀛海故居及墓","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"27","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"潍坊市","项目编号":"20-7-02-3700-039","项目名称":"衡恭王墓保护工程","保护单位名称":"衡恭王墓","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"150","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"潍坊市","项目编号":"20-7-02-3700-042","项目名称":"潍坊市青州青年会旧址修缮保护工程","保护单位名称":"青年会旧址","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"20","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"潍坊市","项目编号":"20-7-02-3700-151","项目名称":"潍城城隍庙二期修缮工程","保护单位名称":"潍城城隍庙","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"160","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"烟台市","项目编号":"20-5-13-3700-040","项目名称":"龙口市博物馆馆藏金属文物保护修复","保护单位名称":"龙口市博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"150","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"烟台市","项目编号":"20-5-12-3700-093","项目名称":"地雷战纪念馆可移动文物预防性保护项目","保护单位名称":"海阳市博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"150","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"烟台市","项目编号":"20-1-12-3700-079","项目名称":"北庄遗址边坡加固项目","保护单位名称":"北庄遗址","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"600","保护单位类型":"古遗址","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"烟台市","项目编号":"20-1-02-3700-178","项目名称":"蓬莱水城南小海护墙、护坡维修工程","保护单位名称":"蓬莱水城及蓬莱阁","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"720","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"烟台市","项目编号":"21-1-03-3700-004","项目名称":"赵疃地雷战遗址安防工程","保护单位名称":"赵疃地雷战遗址","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"100","保护单位类型":"近现代","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"烟台市","项目编号":"20-1-02-3700-035","项目名称":"山东省栖霞市牟氏庄园日新堂寝楼、西忠来佛堂、剪纸楼修缮工程","保护单位名称":"牟氏庄园","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"80","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"烟台市","项目编号":"20-1-05-3700-183","项目名称":"蓬莱阁古建筑群消防工程","保护单位名称":"蓬莱水城及蓬莱阁","补助范围":"全国重点文物保护单位保护","补助事项":"消防","安排金额（万元）":"850","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"烟台市","项目编号":"20-1-03-3700-152","项目名称":"蓬莱水城及蓬莱阁安防升级改造工程","保护单位名称":"蓬莱水城及蓬莱阁","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"540","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"烟台市","项目编号":"20-1-02-3700-109","项目名称":"烟台福建会馆油饰彩画保护工程","保护单位名称":"烟台福建会馆","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"930","保护单位类型":"近现代","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"枣庄市","项目编号":"20-4-15-3700-160","项目名称":"枣庄市薛城区安阳故城考古勘探","保护单位名称":"安阳故城","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"300","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"枣庄市","项目编号":"20-5-14-3700-201","项目名称":"滕州市汉画像石馆数字化保护项目","保护单位名称":"滕州市汉画像石馆","补助范围":"可移动文物保护","补助事项":"数字化保护","安排金额（万元）":"90","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"枣庄市","项目编号":"20-5-14-3700-022","项目名称":"台儿庄大战纪念馆馆藏文物数字化保护项目","保护单位名称":"枣庄市台儿庄区台儿庄大战纪念馆","补助范围":"可移动文物保护","补助事项":"数字化保护","安排金额（万元）":"250","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"枣庄市","项目编号":"20-7-02-3700-162","项目名称":"枣庄市薛城区孙氏故宅西客厅维修","保护单位名称":"孙氏故宅","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"50","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"枣庄市","项目编号":"20-7-02-3700-182","项目名称":"枣庄市薛城区清漳桥修缮保护","保护单位名称":"中陈郝古桥（清漳桥）","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"50","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"枣庄市","项目编号":"20-7-02-3700-161","项目名称":"西仓古桥修缮保护工程","保护单位名称":"西仓古桥","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"240","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-4-15-3700-198","项目名称":"2021年青岛西海岸新区水下文物资源陆地调查","保护单位名称":"山东省水下考古研究中心","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"70","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-4-15-3700-247","项目名称":"山东省石窟寺专项调查","保护单位名称":"曲阜孔庙及孔府","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"370","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-4-15-3700-054","项目名称":"《滕州西孟庄龙山文化聚落》图书出版","保护单位名称":"滕州西孟庄","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"30","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-4-15-3700-051","项目名称":"《海岱考古》（第十三、十四辑）图书出版","保护单位名称":"临淄墓群","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"80","保护单位类型":"古墓葬","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-4-15-3700-065","项目名称":"滨州市省级文物保护单位大盖遗址（大郭遗址）考古调查勘探项目","保护单位名称":"大盖（大郭）遗址","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"30","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-4-15-3700-218","项目名称":"大丁庙墓群考古勘探项目","保护单位名称":"大丁庙墓群","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"230","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-4-15-3700-053","项目名称":"茌平尚庄、五莲丹土资料整理项目","保护单位名称":"尚庄遗址","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"30","保护单位类型":"古遗址","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-4-15-3700-052","项目名称":"《昌邑辛置》（全四册）图书出版","保护单位名称":"昌邑辛置墓地","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"150","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-4-15-3700-222","项目名称":"汾水王海岛金山寺遗址考古勘探项目","保护单位名称":"汾水王海岛金山寺遗址","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"150","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"4-15-21-3700-005","项目名称":"东营市垦利区海北遗址考古发掘","保护单位名称":"海北遗址","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"157","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"4-15-21-3700-009","项目名称":"2021年临淄齐故城考古发掘","保护单位名称":"临淄齐国故城","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"114","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"4-15-21-3700-007","项目名称":"2021年度滕州岗上遗址考古发掘","保护单位名称":"岗上遗址","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"92","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"4-15-21-3700-008","项目名称":"2021年博兴县龙华寺四号建筑基址","保护单位名称":"龙华寺遗址","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"69","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-5-13-3700-189","项目名称":"山东东明县博物馆馆藏纸质文物保护修复","保护单位名称":"东明县博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"100","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-5-13-3700-117","项目名称":"山东东平县博物馆馆藏金属文物保护修复","保护单位名称":"东平县博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"87","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-5-13-3700-095","项目名称":"山东曲阜杏坛学校东周墓地出土青铜器保护修复项目","保护单位名称":"山东省文物考古研究院","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"70","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-5-13-3700-062","项目名称":"山东博物馆馆藏纺织品保护修复项目","保护单位名称":"山东博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"170","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-5-12-3700-049","项目名称":"山东省石刻艺术博物馆馆藏文物预防性保护项目","保护单位名称":"山东省石刻艺术博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"240","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-5-14-3700-125","项目名称":"山东博物馆馆藏珍贵革命文物数字化保护及山东省革命文物数据库建设","保护单位名称":"山东博物馆","补助范围":"可移动文物保护","补助事项":"数字化保护","安排金额（万元）":"230","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-5-14-3700-168","项目名称":"山东省巨野县博物馆馆藏文物数字化采集与信息化管理平台建设","保护单位名称":"巨野县博物馆","补助范围":"可移动文物保护","补助事项":"数字化保护","安排金额（万元）":"100","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-5-13-3700-173","项目名称":"泰安市博物馆馆藏明万历元年龙凤纹四兽足铁香炉保护修复项目","保护单位名称":"山东省文物保护修复中心","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"90","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"19-5-12-3700-285","项目名称":"山东临朐山旺古生物化石博物馆可移动文物预防性保护","保护单位名称":"山东临朐山旺古生物化石博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"300","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-5-13-3700-172","项目名称":"山东淄博市西汉齐王墓出土金属文物保护修复项目","保护单位名称":"山东省文物保护修复中心","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"130","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-5-12-3700-050","项目名称":"惠民博物馆可移动文物预防性保护","保护单位名称":"惠民博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"180","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"21-5-13-3700-048","项目名称":"蒙阴县文物管理所馆藏青铜器保护修复","保护单位名称":"蒙阴县文物管理所","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"70","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"19-5-13-3700-292","项目名称":"中国鲁锦博物馆纸质文物保护项目","保护单位名称":"中国鲁锦博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"260","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-5-12-3700-163","项目名称":"高青县博物馆馆藏文物预防性保护项目","保护单位名称":"高青县博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"100","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-5-14-3700-199","项目名称":"东明县博物馆数字化保护项目","保护单位名称":"东明县博物馆","补助范围":"可移动文物保护","补助事项":"数字化保护","安排金额（万元）":"160","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-5-13-3700-068","项目名称":"蒙阴县文物管理所藏东指村城隍庙彩绘泥塑俑等保护修复项目","保护单位名称":"蒙阴县文物管理所","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"100","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-5-13-3700-072","项目名称":"宁阳县博物馆馆藏陶瓷器文物保护修复工程","保护单位名称":"宁阳县博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"90","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-5-13-3700-061","项目名称":"山东博物馆馆藏九龙山汉墓错鎏金铜器保护修复项目","保护单位名称":"山东博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"40","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-5-12-3700-165","项目名称":"山东省文物保护修复中心预防性保护项目","保护单位名称":"山东省文物保护修复中心","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"140","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-5-13-3700-174","项目名称":"山东临朐山旺古生物化石博物馆馆藏金属文物保护修复项目","保护单位名称":"山东省文物保护修复中心","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"160","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-1-03-3700-177","项目名称":"郓城观音寺塔安防工程","保护单位名称":"郓城观音寺塔","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"40","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"21-1-06-3700-250","项目名称":"大运河南旺枢纽数字化及展示工程","保护单位名称":"大运河（京杭大运河）","补助范围":"全国重点文物保护单位保护","补助事项":"陈列展示","安排金额（万元）":"100","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"21-1-06-3700-249","项目名称":"大运河十里闸保护展示工程","保护单位名称":"大运河（京杭大运河）","补助范围":"全国重点文物保护单位保护","补助事项":"陈列展示","安排金额（万元）":"400","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-1-02-3700-063","项目名称":"梁山县青堌堆遗址考古勘探及报告编制","保护单位名称":"青堌堆遗址","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"100","保护单位类型":"古遗址","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-1-02-3700-229","项目名称":"原齐鲁大学近现代建筑群——桐荫阁、教授别墅-青杨路9号修缮保护工程","保护单位名称":"原齐鲁大学近现代建筑群","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"170","保护单位类型":"近现代","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-1-02-3700-197","项目名称":"南武城故城遗址北墙西段、西墙保护修缮","保护单位名称":"南武城故城遗址","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"180","保护单位类型":"古遗址","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-1-02-3700-230","项目名称":"原齐鲁大学近现代建筑群-青杨路5#楼修缮保护工程","保护单位名称":"原齐鲁大学近现代建筑群","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"150","保护单位类型":"近现代","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-1-03-3700-221","项目名称":"成武县卧化塔安防工程","保护单位名称":"卧化塔","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"57","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"21-1-02-3700-248","项目名称":"大运河荆门上、下闸段阶梯闸保护展示工程","保护单位名称":"大运河（京杭大运河）","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"482","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"1-12-21-3700-015","项目名称":"2021-2022 年度曲阜孔庙及孔府预防性保护","保护单位名称":"曲阜孔庙及孔府","补助范围":"全国重点文物保护单位保护","补助事项":"预防性保护","安排金额（万元）":"431","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"1-02-21-3700-010","项目名称":"泰山古建筑群-南天门抢险加固工程","保护单位名称":"泰山古建筑群","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"376","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"1-02-21-3700-001","项目名称":"萧王庄墓群一号墓抢险加固工程","保护单位名称":"萧王庄墓群","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"253","保护单位类型":"古墓葬","项目类别":"重点"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"21-1-02-3700-016","项目名称":"坊子德日建筑群——日本鲁大煤炭产销公司西沿街房抢险工程","保护单位名称":"坊子德日建筑群","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"85","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-7-02-3700-237","项目名称":"德州市临邑县理合务镇蔺家宅院修缮保护工程","保护单位名称":"蔺家宅院","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"45","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-7-02-3700-219","项目名称":"微山县两城镇房头北村城门抢救性修缮保护工程","保护单位名称":"房头北村城门","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"70","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-7-02-3700-190","项目名称":"大运河遗产南旺分水枢纽本体保护工程——三元庙保护","保护单位名称":"三元庙","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"40","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-7-02-3700-243","项目名称":"宁津县长官清真寺围墙及院落排水抢险加固工程","保护单位名称":"长官清真寺","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"21","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-7-02-3700-166","项目名称":"孟良崮战役遗址——孟良崮医院、孟良崮完小修缮保护工程","保护单位名称":"孟良崮战役遗址","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"90","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-7-02-3700-179","项目名称":"王丕庄古建筑-花厅抢险加固工程","保护单位名称":"王丕庄古建筑","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"60","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-7-02-3700-235","项目名称":"东平县前口头村戴家楼修缮工程","保护单位名称":"前口头古民居","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"89","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-7-02-3700-088","项目名称":"宁阳灵山寺修缮保护工程","保护单位名称":"宁阳灵山寺","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"260","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-7-02-3700-089","项目名称":"宁阳关圣帝君碑保护工程","保护单位名称":"关圣帝君庙碑","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"20","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"19-7-02-3700-150","项目名称":"莒国故城城墙保护工程","保护单位名称":"莒国故城","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"180","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-7-02-3700-080","项目名称":"潍坊市临朐县孙家庄村三皇庙维修保护项目","保护单位名称":"三皇庙","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"38","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-7-02-3700-078","项目名称":"泗水泉林-碑刻及紫锦湖石舫修缮保护工程","保护单位名称":"泗水泉林","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"140","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-7-03-3700-029","项目名称":"冠县陈镛墓抢修保护项目","保护单位名称":"陈镛墓","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"48","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"直属","项目编号":"20-7-31-3700-208","项目名称":"刘邓大军指挥部旧址修缮工程","保护单位名称":"刘邓大军指挥部旧址","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"58","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"淄博市","项目编号":"19-5-12-3700-354","项目名称":"桓台博物馆可移动文物预防性保护项目","保护单位名称":"桓台博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"150","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"淄博市","项目编号":"20-5-13-3700-030","项目名称":"桓台博物馆馆藏精品文物数字化保护项目","保护单位名称":"桓台博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"180","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"淄博市","项目编号":"20-5-13-3700-090","项目名称":"周村区文物管护服务中心藏陶瓷文物保护修复项目","保护单位名称":"周村区文物管护服务中心","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"100","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"淄博市","项目编号":"19-5-12-3700-374","项目名称":"淄川博物馆可移动文物预防性保护项目","保护单位名称":"淄川区博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"130","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"淄博市","项目编号":"19-5-13-3700-270","项目名称":"淄博市博物馆馆藏革命文物修复保护项目","保护单位名称":"淄博市博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"150","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2021","市级":"淄博市","项目编号":"20-5-13-3700-126","项目名称":"淄川区博物馆馆藏公孙墓地壁画保护修复项目","保护单位名称":"淄川区博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"50","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"滨州市","项目编号":"19-4-15-3700-392","项目名称":"滨州市无棣县城角遗址考古勘探项目","保护单位名称":"城角遗址","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"50","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"滨州市","项目编号":"19-4-15-3700-392","项目名称":"滨州市无棣县城角遗址考古勘探项目","保护单位名称":"城角遗址","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"36","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"滨州市","项目编号":"19-5-13-3700-268","项目名称":"山东无棣县文物管理局馆藏纸质文物保护修复项目","保护单位名称":"无棣县文物管理局","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"80","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"滨州市","项目编号":"19-5-13-3700-268","项目名称":"山东无棣县文物管理局馆藏纸质文物保护修复项目","保护单位名称":"无棣县文物管理局","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"60","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"滨州市","项目编号":"19-5-13-3700-310","项目名称":"山东滨州市博物馆馆藏陶瓷器文物保护修复项目","保护单位名称":"滨州市博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"139","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"滨州市","项目编号":"19-5-14-3700-300","项目名称":"沾化区博物馆文物数字化保护项目","保护单位名称":"沾化区博物馆","补助范围":"可移动文物保护","补助事项":"数字化保护","安排金额（万元）":"125","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"滨州市","项目编号":"19-5-13-3700-318","项目名称":"惠民博物馆馆藏纸质文物保护项目","保护单位名称":"惠民县博物馆（文物事业管理处）","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"110","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"滨州市","项目编号":"19-5-14-3700-330","项目名称":"滨州市博物馆文物数字化保护项目","保护单位名称":"滨州市博物馆","补助范围":"可移动文物保护","补助事项":"数字化保护","安排金额（万元）":"160","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"滨州市","项目编号":"19-5-13-3700-368","项目名称":"邹平市博物馆馆藏纸质文物保护修复项目","保护单位名称":"邹平市博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"55","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"滨州市","项目编号":"19-5-12-3700-329","项目名称":"滨州市博物馆可移动文物预防性保护项目","保护单位名称":"滨州市博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"100","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"德州市","项目编号":"19-4-15-3700-363","项目名称":"惠王冢遗址考古勘探","保护单位名称":"惠王冢遗址","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"55","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"德州市","项目编号":"19-5-12-3700-263","项目名称":"德州市博物馆馆藏文物预防性保护项目","保护单位名称":"德州市博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"240","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"德州市","项目编号":"19-5-12-3700-263","项目名称":"德州市博物馆馆藏文物预防性保护项目","保护单位名称":"德州市博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"210","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"德州市","项目编号":"19-5-14-3700-262","项目名称":"德州市博物馆数字化保护项目","保护单位名称":"德州市博物馆","补助范围":"可移动文物保护","补助事项":"数字化保护","安排金额（万元）":"200","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"德州市","项目编号":"19-5-14-3700-262","项目名称":"德州市博物馆数字化保护项目","保护单位名称":"德州市博物馆","补助范围":"可移动文物保护","补助事项":"数字化保护","安排金额（万元）":"190","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"德州市","项目编号":"19-5-13-3700-393","项目名称":"冀鲁边区革命纪念馆馆藏革命文物保护修复项目","保护单位名称":"冀鲁边区革命纪念馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"100","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"德州市","项目编号":"19-5-13-3700-393","项目名称":"冀鲁边区革命纪念馆馆藏革命文物保护修复项目","保护单位名称":"冀鲁边区革命纪念馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"120","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"德州市","项目编号":"19-7-02-3700-362","项目名称":"德州市临邑县德平镇白麟书院修缮保护","保护单位名称":"白麟书院","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"54","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"德州市","项目编号":"19-7-02-3700-409","项目名称":"夏津文庙大成殿修缮保护工程","保护单位名称":"夏津文庙大成殿","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"160","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"东营市","项目编号":"19-5-12-3700-248","项目名称":"利津县博物馆博物馆可移动文物预防性保护项目","保护单位名称":"利津县博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"100","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"东营市","项目编号":"19-5-12-3700-248","项目名称":"利津县博物馆博物馆可移动文物预防性保护项目","保护单位名称":"利津县博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"100","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"东营市","项目编号":"19-5-12-3700-273","项目名称":"东营市垦利区博物馆文物预防性保护项目","保护单位名称":"垦利区博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"90","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"东营市","项目编号":"19-5-14-3700-258","项目名称":"东营市历史博物馆文物数字化保护工程","保护单位名称":"东营市历史博物馆","补助范围":"可移动文物保护","补助事项":"数字化保护","安排金额（万元）":"110","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"东营市","项目编号":"19-5-14-3700-277","项目名称":"东营市垦利区博物馆文物数字化保护项目","保护单位名称":"垦利区博物馆","补助范围":"可移动文物保护","补助事项":"数字化保护","安排金额（万元）":"100","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"菏泽市","项目编号":"19-4-15-3700-372","项目名称":"巨野县昌邑故城址2020-2022年考古勘探","保护单位名称":"昌邑故城址","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"400","保护单位类型":"古遗址","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"菏泽市","项目编号":"19-4-15-3700-376","项目名称":"巨野昌邑故国诸侯王墓区域系统调查与勘探","保护单位名称":"昌邑故城址","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"260","保护单位类型":"古遗址","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"菏泽市","项目编号":"19-5-12-3700-256","项目名称":"定陶博物馆馆藏文物预防性保护项目","保护单位名称":"定陶县博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"420","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"菏泽市","项目编号":"19-5-13-3700-286","项目名称":"山东成武县博物馆馆藏纸质文物保护修复","保护单位名称":"成武县博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"130","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"菏泽市","项目编号":"19-5-13-3700-288","项目名称":"巨野县博物馆馆藏金属文物保护修复","保护单位名称":"巨野县博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"110","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"菏泽市","项目编号":"19-5-13-3700-297","项目名称":"菏泽市博物馆馆藏珍贵文物保护修复","保护单位名称":"菏泽市博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"60","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"菏泽市","项目编号":"19-5-13-3700-294","项目名称":"山东成武县博物馆馆藏陶瓷器文物保护修复","保护单位名称":"成武县博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"150","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"菏泽市","项目编号":"19-5-12-3700-296","项目名称":"郓城县文物管理所藏纸质文物保护修复","保护单位名称":"郓城县博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"80","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"菏泽市","项目编号":"19-5-12-3700-296","项目名称":"郓城县文物管理所藏纸质文物保护修复","保护单位名称":"郓城县博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"80","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"菏泽市","项目编号":"19-5-13-3700-328","项目名称":"单县博物馆馆藏陶瓷文物保护项目","保护单位名称":"单县博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"140","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"菏泽市","项目编号":"19-5-13-3700-272","项目名称":"定陶汉墓2019年出土彩绘铭文陶砖保护修复项目","保护单位名称":"定陶王墓地（王陵）","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"417","保护单位类型":"古墓葬","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"菏泽市","项目编号":"19-5-13-3700-280","项目名称":"山东曹县商都博物馆馆藏纸质文物保护修复项目","保护单位名称":"曹县商都博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"65","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"菏泽市","项目编号":"19-5-13-3700-280","项目名称":"山东曹县商都博物馆馆藏纸质文物保护修复项目","保护单位名称":"曹县商都博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"70","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"菏泽市","项目编号":"19-5-13-3700-328","项目名称":"单县博物馆馆藏陶瓷文物保护项目","保护单位名称":"单县博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"100","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"菏泽市","项目编号":"19-5-13-3700-272","项目名称":"定陶汉墓2019年出土彩绘铭文陶砖保护修复项目","保护单位名称":"定陶王墓地（王陵）","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"400","保护单位类型":"古墓葬","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"菏泽市","项目编号":"19-1-02-3700-389","项目名称":"昌邑故城址城垣Ｙ1段保护修缮","保护单位名称":"昌邑故城址","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"49","保护单位类型":"古遗址","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"菏泽市","项目编号":"19-7-02-3700-357","项目名称":"单县东街黄氏民居维修保护工程","保护单位名称":"东街黄氏民居","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"20","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"菏泽市","项目编号":"19-7-02-3700-358","项目名称":"单县李北刘氏民居维修保护工程","保护单位名称":"李北刘氏民居","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"15","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"菏泽市","项目编号":"19-7-02-3700-355","项目名称":"单县高庄村袁景山民居维修工程","保护单位名称":"高庄村袁景山民居","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"15","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"菏泽市","项目编号":"19-7-02-3700-334","项目名称":"成武县刘土城村党支部旧址维修保护","保护单位名称":"刘土城村党支部旧址","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"11","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"菏泽市","项目编号":"19-7-02-3700-349","项目名称":"申氏石坊抢救性保护工程","保护单位名称":"申氏石坊","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"38","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"菏泽市","项目编号":"19-7-31-3700-346","项目名称":"革命文物——巨野县奚氏家祠（羊山战役后方医院）维修保护","保护单位名称":"奚阁村奚氏家祠","补助范围":"省级及省级以下文物保护单位保护","补助事项":"革命文物保护利用片区整体陈列展示","安排金额（万元）":"50","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"菏泽市","项目编号":"19-7-31-3700-346","项目名称":"革命文物——巨野县奚氏家祠（羊山战役后方医院）维修保护","保护单位名称":"奚阁村奚氏家祠","补助范围":"省级及省级以下文物保护单位保护","补助事项":"革命文物保护利用片区整体陈列展示","安排金额（万元）":"32","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"济南市","项目编号":"4-15-19-3700-038","项目名称":"2019年山东省日照市桃花岛及周边海域水下文物资源调查","保护单位名称":"山东省水下考古研究中心","补助范围":"考古","补助事项":"考古调查","安排金额（万元）":"86","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2020","市级":"济南市","项目编号":"19-5-13-3700-305","项目名称":"山东省济南市长清区博物馆馆藏陶瓷文物保护修复","保护单位名称":"济南市长清区博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"38","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"济南市","项目编号":"19-5-12-3700-215","项目名称":"平阴县博物馆馆藏文物预防性保护","保护单位名称":"平阴县博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"110","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"济南市","项目编号":"19-5-14-3700-290","项目名称":"历城区博物馆馆藏文物数字化保护项目","保护单位名称":"历城区博物馆","补助范围":"可移动文物保护","补助事项":"数字化保护","安排金额（万元）":"140","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"济南市","项目编号":"19-5-13-3700-308","项目名称":"山东鲁中抗日战争展览馆馆藏革命文物保护修复","保护单位名称":"莱芜战役纪念馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"120","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"济南市","项目编号":"19-5-13-3700-305","项目名称":"山东省济南市长清区博物馆馆藏陶瓷文物保护修复","保护单位名称":"济南市长清区博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"60","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"济南市","项目编号":"19-5-14-3700-229","项目名称":"济南市章丘区博物馆、城子崖遗址博物馆文物数字化保护项目","保护单位名称":"章丘市博物馆","补助范围":"可移动文物保护","补助事项":"数字化保护","安排金额（万元）":"250","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"济南市","项目编号":"19-5-13-3700-291","项目名称":"历城区博物馆馆藏金属文物保护修复","保护单位名称":"历城区博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"130","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"济南市","项目编号":"19-7-02-3700-322","项目名称":"济南市历城区灵鹫寺大雄宝殿修缮保护工程","保护单位名称":"济南历城灵鹫寺","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"60","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"济宁市","项目编号":"19-5-12-3700-284","项目名称":"汶上县中都博物馆可移动文物预防性保护项目","保护单位名称":"汶上县中都博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"220","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"济宁市","项目编号":"19-5-14-3700-260","项目名称":"济宁市兖州区博物馆文物数字化保护项目","保护单位名称":"兖州市博物馆","补助范围":"可移动文物保护","补助事项":"数字化保护","安排金额（万元）":"110","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"济宁市","项目编号":"19-5-13-3700-315","项目名称":"济宁市博物馆馆藏青铜文物保护项目","保护单位名称":"济宁市博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"139","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"济宁市","项目编号":"19-5-14-3700-333","项目名称":"济宁市博物馆文物数字化保护项目","保护单位名称":"济宁市博物馆","补助范围":"可移动文物保护","补助事项":"数字化保护","安排金额（万元）":"150","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"济宁市","项目编号":"19-5-13-3700-274","项目名称":"梁山县博物馆馆藏纸质文物保护修复项目","保护单位名称":"梁山县博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"195","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"济宁市","项目编号":"19-5-13-3700-299","项目名称":"梁山县博物馆馆藏金属文物保护修复项目","保护单位名称":"梁山县博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"135","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"济宁市","项目编号":"19-1-02-3700-418","项目名称":"兴隆塔地宫修缮工程","保护单位名称":"兴隆塔","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"330","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"济宁市","项目编号":"19-1-02-3700-247","项目名称":"孟庙、孟府和孟林修缮三期工程","保护单位名称":"孟庙、孟府和孟林（孟庙及孟府）","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"690","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"济宁市","项目编号":"19-1-02-3700-404","项目名称":"曲阜孔庙角楼修缮保护","保护单位名称":"曲阜孔庙及孔府","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"230","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"济宁市","项目编号":"19-1-02-3700-415","项目名称":"葛山摩崖石刻本体保护工程","保护单位名称":"葛山和峄山摩崖","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"220","保护单位类型":"石窟寺及石刻","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"济宁市","项目编号":"19-1-02-3700-407","项目名称":"曲阜孔庙后土祠、神厨、神庖古建筑修缮保护工程","保护单位名称":"曲阜孔庙及孔府","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"380","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"济宁市","项目编号":"19-1-03-3700-394","项目名称":"曲阜鲁国故城安防项目","保护单位名称":"曲阜鲁国故城","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"600","保护单位类型":"古遗址","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"济宁市","项目编号":"19-1-12-3700-406","项目名称":"嘉祥武氏墓群石刻保护性设施修缮及扩建工程","保护单位名称":"嘉祥武氏墓群石刻","补助范围":"全国重点文物保护单位保护","补助事项":"预防性保护","安排金额（万元）":"280","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"济宁市","项目编号":"19-1-02-3700-405","项目名称":"山东省嘉祥县曾庙宗圣寝殿、莱芜侯寝殿修缮工程","保护单位名称":"曾庙","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"80","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"济宁市","项目编号":"19-7-02-3700-370","项目名称":"中共曲泗工作委员会旧址暨钱杰东烈士故居修缮保护工程","保护单位名称":"中共曲泗工作委员会旧址暨钱杰东烈士故居","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"19","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"济宁市","项目编号":"19-7-02-3700-390","项目名称":"济宁黄家街教堂保护修缮工程","保护单位名称":"黄家街教堂","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"50","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"济宁市","项目编号":"19-7-05-3700-411","项目名称":"南阳古建筑群消防工程","保护单位名称":"南阳古建筑群","补助范围":"省级及省级以下文物保护单位保护","补助事项":"消防","安排金额（万元）":"120","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"济宁市","项目编号":"19-7-02-3700-390","项目名称":"济宁黄家街教堂保护修缮工程","保护单位名称":"黄家街教堂","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"50","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"济宁市","项目编号":"19-7-05-3700-412","项目名称":"泗水仲庙消防工程","保护单位名称":"泗水仲庙","补助范围":"省级及省级以下文物保护单位保护","补助事项":"消防","安排金额（万元）":"100","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"济宁市","项目编号":"19-7-02-3700-325","项目名称":"郭氏家祠维修","保护单位名称":"郭氏家祠","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"100","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"济宁市","项目编号":"19-7-02-3700-369","项目名称":"泗水回龙桥修缮保护工程","保护单位名称":"回龙桥","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"220","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"济宁市","项目编号":"19-7-05-3700-411","项目名称":"南阳古建筑群消防工程","保护单位名称":"南阳古建筑群","补助范围":"省级及省级以下文物保护单位保护","补助事项":"消防","安排金额（万元）":"260","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"济宁市","项目编号":"19-7-02-3700-398","项目名称":"凰翥文昌阁修缮工程","保护单位名称":"凰翥文昌阁","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"71","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"聊城市","项目编号":"19-5-12-3700-269","项目名称":"孔繁森同志纪念馆可移动文物预防性保护","保护单位名称":"孔繁森同志纪念馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"190","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"聊城市","项目编号":"19-5-12-3700-311","项目名称":"临清市博物馆馆藏文物预防性保护","保护单位名称":"山东省临清市博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"130","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"聊城市","项目编号":"19-5-12-3700-269","项目名称":"孔繁森同志纪念馆可移动文物预防性保护","保护单位名称":"孔繁森同志纪念馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"100","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"聊城市","项目编号":"19-7-02-3700-350","项目名称":"张秋山陕会馆戏楼抢修保护工程","保护单位名称":"阳谷张秋山陕会馆","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"40","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"聊城市","项目编号":"19-7-02-3700-422","项目名称":"聊城北杨集革命烈士纪念亭抢救性修缮保护","保护单位名称":"聊城北杨集革命烈士纪念亭","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"50","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"临沂市","项目编号":"19-5-12-3700-116","项目名称":"莒南县博物馆可移动文物预防性保护","保护单位名称":"莒南县博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"100","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"临沂市","项目编号":"19-5-12-3700-312","项目名称":"沂南县博物馆可移动文物预防性保护项目","保护单位名称":"山东省沂南县博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"80","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"临沂市","项目编号":"19-5-14-3700-319","项目名称":"沂南县博物馆馆藏文物数字化保护","保护单位名称":"山东省沂南县博物馆","补助范围":"可移动文物保护","补助事项":"数字化保护","安排金额（万元）":"80","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"临沂市","项目编号":"19-5-12-3700-116","项目名称":"莒南县博物馆可移动文物预防性保护","保护单位名称":"莒南县博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"110","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"临沂市","项目编号":"19-5-12-3700-312","项目名称":"沂南县博物馆可移动文物预防性保护项目","保护单位名称":"山东省沂南县博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"90","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"临沂市","项目编号":"19-1-03-3700-386","项目名称":"北沟头遗址安防工程","保护单位名称":"北沟头遗址","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"60","保护单位类型":"古遗址","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"临沂市","项目编号":"19-1-05-3700-385","项目名称":"八路军115师司令部旧址消防工程","保护单位名称":"八路军一一五师司令部旧址","补助范围":"全国重点文物保护单位保护","补助事项":"消防","安排金额（万元）":"230","保护单位类型":"近现代","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"临沂市","项目编号":"19-1-03-3700-386","项目名称":"北沟头遗址安防工程","保护单位名称":"北沟头遗址","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"220","保护单位类型":"古遗址","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"日照市","项目编号":"19-5-14-3700-246","项目名称":"莒县博物馆文物数字化保护利用项目","保护单位名称":"山东莒县博物馆","补助范围":"可移动文物保护","补助事项":"数字化保护","安排金额（万元）":"220","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"日照市","项目编号":"19-1-02-3700-424","项目名称":"东海峪遗址本体围护工程","保护单位名称":"东海峪遗址","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"400","保护单位类型":"古遗址","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"泰安市","项目编号":"19-5-13-3700-283","项目名称":"宁阳县博物馆馆藏象牙化石保护修复","保护单位名称":"宁阳县博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"13","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"泰安市","项目编号":"19-5-12-3700-238","项目名称":"新泰市博物馆可移动文物预防性保护","保护单位名称":"新泰市博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"220","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"泰安市","项目编号":"19-1-04-3700-387","项目名称":"宁阳颜子庙和颜林古建筑防雷保护工程","保护单位名称":"宁阳颜子庙和颜林","补助范围":"全国重点文物保护单位保护","补助事项":"防雷","安排金额（万元）":"60","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"泰安市","项目编号":"19-1-03-3700-388","项目名称":"徂徕山抗日武装起义旧址安防工程","保护单位名称":"徂徕山抗日武装起义旧址","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"73","保护单位类型":"近现代","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"泰安市","项目编号":"19-1-06-3700-416","项目名称":"泰山石刻保护前期勘察研究","保护单位名称":"泰山石刻","补助范围":"全国重点文物保护单位保护","补助事项":"陈列展示","安排金额（万元）":"460","保护单位类型":"石窟寺及石刻","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"泰安市","项目编号":"19-7-02-3700-378","项目名称":"朱氏民居维修工程","保护单位名称":"朱氏民居","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"50","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"泰安市","项目编号":"19-7-02-3700-414","项目名称":"五埠村古民居抢救性维修工程","保护单位名称":"五埠村古民居","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"100","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"泰安市","项目编号":"19-7-02-3700-421","项目名称":"陶南山馆建筑群修缮保护工程","保护单位名称":"陶南山馆建筑群","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"150","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"泰安市","项目编号":"19-7-02-3700-401","项目名称":"山东省委旧址暨八路军山东抗日游击第四支队司令部旧址修缮工程","保护单位名称":"山东省委旧址暨八路军山东抗日游击第四支队司令部旧址","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"117","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"泰安市","项目编号":"19-7-02-3700-421","项目名称":"陶南山馆建筑群修缮保护工程","保护单位名称":"陶南山馆建筑群","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"162","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"威海市","项目编号":"19-4-15-3700-397","项目名称":"育犁故城考古勘探及报告编制","保护单位名称":"乳山市文物管理所","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"80","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"威海市","项目编号":"19-5-13-3700-395","项目名称":"乳山市文物保护中心馆藏书画文物保护修复项目","保护单位名称":"乳山市文物管理所","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"120","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"威海市","项目编号":"19-5-12-3700-211","项目名称":"乳山市文物保护中心可移动文物预防性保护项目","保护单位名称":"乳山市文物管理所","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"120","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"威海市","项目编号":"19-5-13-3700-316","项目名称":"山东天福山起义纪念馆馆藏革命文物修复保护项目","保护单位名称":"文登市天福山起义纪念馆（天福山革命遗址管理所）","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"120","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"威海市","项目编号":"19-7-02-3700-335","项目名称":"宁津所城城墙保护工程项目","保护单位名称":"宁津所城","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"180","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"威海市","项目编号":"19-7-02-3700-337","项目名称":"郭永怀故居保护修缮工程","保护单位名称":"郭永怀故居","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"90","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"威海市","项目编号":"19-7-02-3700-337","项目名称":"郭永怀故居保护修缮工程","保护单位名称":"郭永怀故居","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"90","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"威海市","项目编号":"19-7-02-3700-336","项目名称":"宁津所城遗址考古勘探项目","保护单位名称":"宁津所城","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"51","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"潍坊市","项目编号":"19-5-13-3700-282","项目名称":"青州市博物馆馆藏书画保护修复项目","保护单位名称":"青州市博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"160","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"潍坊市","项目编号":"19-5-13-3700-281","项目名称":"潍坊市博物馆馆藏纸质文物（碑帖拓片）保护修复项目","保护单位名称":"潍坊市博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"190","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"潍坊市","项目编号":"19-5-13-3700-365","项目名称":"山东昌乐县博物馆馆藏石质文物保护修复项目","保护单位名称":"昌乐县博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"200","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"潍坊市","项目编号":"19-5-14-3700-216","项目名称":"寿光市博物馆文物数字化保护项目","保护单位名称":"寿光市博物馆","补助范围":"可移动文物保护","补助事项":"数字化保护","安排金额（万元）":"123","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"潍坊市","项目编号":"19-5-13-3700-276","项目名称":"潍坊市博物馆馆藏一级文物“清郑燮书<静俭斋>横批”保护修复项目","保护单位名称":"潍坊市博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"4","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"潍坊市","项目编号":"19-5-13-3700-278","项目名称":"潍坊市博物馆馆藏一级文物“清乾隆十三年郑燮书<修城记>四条屏”保护修复项目","保护单位名称":"潍坊市博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"24","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"潍坊市","项目编号":"19-1-02-3700-012","项目名称":"驼山石窟综合保护前期勘查研究","保护单位名称":"驼山石窟","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"400","保护单位类型":"石窟寺及石刻","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"潍坊市","项目编号":"19-7-02-3700-313","项目名称":"昌邑市柳疃镇高隆盛村古庙维修工程","保护单位名称":"高隆盛三官庙","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"60","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"潍坊市","项目编号":"19-7-02-3700-261","项目名称":"潍县战役指挥部旧址保护修缮工程","保护单位名称":"潍县战役指挥部旧址","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"30","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"潍坊市","项目编号":"19-7-02-3700-257","项目名称":"潍坊市潍城区郭味蕖故居＂疏园＂维修保护工程","保护单位名称":"郭味蕖故居","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"77","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"潍坊市","项目编号":"19-7-02-3700-352","项目名称":"潍坊市潍城玉清宫修缮保护工程","保护单位名称":"潍县战役纪念地","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"61","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"潍坊市","项目编号":"19-7-02-3700-419","项目名称":"潍坊市峡山区岞山火车站旧址修缮工程","保护单位名称":"岞山火车站旧址","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"100","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"潍坊市","项目编号":"19-7-02-3700-351","项目名称":"潍坊市潍县城墙--东段抢救性保护工程","保护单位名称":"潍县战役纪念地","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"80","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"烟台市","项目编号":"19-5-13-3700-323","项目名称":"许世友在胶东纪念馆馆藏革命文物保护修复","保护单位名称":"许世友在胶东纪念馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"60","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"烟台市","项目编号":"19-5-13-3700-323","项目名称":"许世友在胶东纪念馆馆藏革命文物保护修复","保护单位名称":"许世友在胶东纪念馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"40","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"烟台市","项目编号":"19-5-13-3700-343","项目名称":"山东海阳市博物馆藏青铜器保护修复","保护单位名称":"海阳市博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"20","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"烟台市","项目编号":"19-5-13-3700-345","项目名称":"莱阳市博物馆馆藏纸质文物保护修复","保护单位名称":"莱阳市博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"110","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"烟台市","项目编号":"19-5-13-3700-338","项目名称":"莱州市博物馆馆藏纸质文物保护修复","保护单位名称":"莱州市博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"260","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"烟台市","项目编号":"19-5-12-3700-348","项目名称":"烟台市牟平区可移动文物预防性保护项目","保护单位名称":"牟平区博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"120","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"烟台市","项目编号":"19-5-12-3700-348","项目名称":"烟台市牟平区可移动文物预防性保护项目","保护单位名称":"牟平区博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"40","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"烟台市","项目编号":"19-5-12-3700-360","项目名称":"牟氏庄园可移动文物预防性保护","保护单位名称":"牟氏庄园","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"120","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"烟台市","项目编号":"19-1-02-3700-420","项目名称":"蓬莱阁丹崖山岩体加固工程","保护单位名称":"蓬莱水城及蓬莱阁","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"400","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"烟台市","项目编号":"19-1-02-3700-420","项目名称":"蓬莱阁丹崖山岩体加固工程","保护单位名称":"蓬莱水城及蓬莱阁","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"450","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"枣庄市","项目编号":"19-5-12-3700-304","项目名称":"王学仲艺术馆可移动文物预防性保护","保护单位名称":"王学仲艺术馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"110","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"枣庄市","项目编号":"19-5-12-3700-368","项目名称":"台儿庄大战纪念馆馆藏文物预防性保护","保护单位名称":"枣庄市台儿庄区台儿庄大战纪念馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"60","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"枣庄市","项目编号":"19-5-12-3700-368","项目名称":"台儿庄大战纪念馆馆藏文物预防性保护","保护单位名称":"枣庄市台儿庄区台儿庄大战纪念馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"70","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"枣庄市","项目编号":"19-5-13-3700-309","项目名称":"枣庄市博物馆馆藏金属文物保护修复项目","保护单位名称":"枣庄市博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"56","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"枣庄市","项目编号":"19-5-13-3700-309","项目名称":"枣庄市博物馆馆藏金属文物保护修复项目","保护单位名称":"枣庄市博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"120","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"枣庄市","项目编号":"19-5-12-3700-304","项目名称":"王学仲艺术馆可移动文物预防性保护","保护单位名称":"王学仲艺术馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"110","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"枣庄市","项目编号":"19-7-02-3700-306","项目名称":"华德中兴煤矿公司电务处修缮工程","保护单位名称":"华德中兴煤矿公司旧址","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"120","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"直属","项目编号":"19-4-15-3700-413","项目名称":"2019-2022年山东地区旧石器时代晚期至新石器时代早期遗址调查与研究","保护单位名称":"凤凰岭旧石器遗址","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"33","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"直属","项目编号":"4-15-20-3700-004","项目名称":"滕州岗上遗址考古发掘项目","保护单位名称":"岗上遗址","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"120","保护单位类型":"古遗址","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"直属","项目编号":"19-1-30-3700-371","项目名称":"《八主祠》和《临淄齐故城冶铸考古》的图书出版","保护单位名称":"临淄齐国故城","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"179","保护单位类型":"古遗址","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"直属","项目编号":"4-15-20-3700-006","项目名称":"山东省济南市历城区大辛庄遗址2020年度考古发掘","保护单位名称":"大辛庄遗址","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"105","保护单位类型":"古遗址","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"直属","项目编号":"4-15-20-3700-001","项目名称":"2020年临淄齐故城考古发掘","保护单位名称":"临淄齐国故城","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"80","保护单位类型":"古遗址","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"直属","项目编号":"19-4-15-3700-423","项目名称":"2020年东营市垦利区海北遗址考古勘探","保护单位名称":"山东省水下考古研究中心","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"140","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"直属","项目编号":"4-15-20-3700-007","项目名称":"山东省济南市章丘区焦家遗址","保护单位名称":"焦家遗址","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"100","保护单位类型":"古遗址","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"直属","项目编号":"20-4-15-3700-014","项目名称":"山东沿黄地区文物资源调查项目","保护单位名称":"长城——齐长城遗址","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"67","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"直属","项目编号":"4-15-20-3700-010","项目名称":"2020年琅玡台遗址考古发掘","保护单位名称":"琅琊台遗址","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"160","保护单位类型":"古遗址","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"直属","项目编号":"4-15-20-3700-005","项目名称":"山东滕州东莱墓地考古勘探发掘项目","保护单位名称":"东莱墓地","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"130","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"直属","项目编号":"4-15-20-3700-002","项目名称":"2020年山东博兴龙华寺考古发掘","保护单位名称":"龙华寺遗址","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"131","保护单位类型":"古遗址","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"直属","项目编号":"19-5-13-3700-320","项目名称":"山东博物馆馆藏返铅书画保护项目","保护单位名称":"山东博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"26","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"直属","项目编号":"19-5-13-3700-342","项目名称":"章丘区三德范村出土纸质文物保护修复项目","保护单位名称":"山东省文物保护修复中心","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"60","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"直属","项目编号":"19-5-13-3700-340","项目名称":"昌邑市博物馆馆藏青铜器保护修复项目","保护单位名称":"山东省文物保护修复中心","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"50","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"直属","项目编号":"19-5-13-3700-324","项目名称":"山东博物馆藏全形拓片保护项目","保护单位名称":"山东博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"50","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"直属","项目编号":"19-5-13-3700-301","项目名称":"山东博物馆馆藏苏埠屯等一批青铜器保护修复","保护单位名称":"山东博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"20","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"直属","项目编号":"19-5-12-3700-396","项目名称":"孔子博物馆文物预防性保护项目","保护单位名称":"孔子博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"55","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"直属","项目编号":"19-5-12-3700-331","项目名称":"山东省文物保护修复中心文物保护工场预防性保护项目","保护单位名称":"山东省文物保护修复中心","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"300","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"直属","项目编号":"19-5-13-3700-340","项目名称":"昌邑市博物馆馆藏青铜器保护修复项目","保护单位名称":"山东省文物保护修复中心","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"100","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"直属","项目编号":"19-5-13-3700-302","项目名称":"孔子博物馆馆藏纺织品保护修复项目","保护单位名称":"孔子博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"70","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"直属","项目编号":"19-5-13-3700-341","项目名称":"昌邑市博物馆馆藏书画保护修复项目","保护单位名称":"山东省文物保护修复中心","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"88","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"直属","项目编号":"19-5-13-3700-279","项目名称":"孔子博物馆馆藏青铜器保护修复项目","保护单位名称":"孔子博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"80","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"直属","项目编号":"19-5-13-3700-339","项目名称":"洛庄汉墓出土错金银车马饰及铁质文物保护修复项目","保护单位名称":"山东省文物保护修复中心","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"77","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"直属","项目编号":"19-1-03-3700-381","项目名称":"魏家庄遗址安防工程","保护单位名称":"魏家庄遗址","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"190","保护单位类型":"古遗址","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"直属","项目编号":"19-1-03-3700-382","项目名称":"西朱封遗址安防工程","保护单位名称":"西朱封遗址","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"200","保护单位类型":"古遗址","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"直属","项目编号":"1-05-19-3700-036","项目名称":"聊城山陕会馆消防工程","保护单位名称":"聊城山陕会馆","补助范围":"全国重点文物保护单位保护","补助事项":"消防","安排金额（万元）":"157","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2020","市级":"直属","项目编号":"19-7-02-3700-391","项目名称":"鄄城县苏老家苏恩墓地保护项目","保护单位名称":"苏老家苏恩墓","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"80","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"直属","项目编号":"19-7-02-3700-326","项目名称":"中共夏营支部旧址保护修缮工程","保护单位名称":"中共夏营支部旧址","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"100","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"直属","项目编号":"19-7-02-3700-391","项目名称":"鄄城县苏老家苏恩墓地保护项目","保护单位名称":"苏老家苏恩墓","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"80","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"淄博市","项目编号":"19-5-12-3700-307","项目名称":"临淄中国古车博物馆馆藏文物预防性保护项目","保护单位名称":"临淄中国古车博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"100","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"淄博市","项目编号":"19-5-13-3700-375","项目名称":"淄川博物馆瓷器文物保护项目","保护单位名称":"淄川区博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"120","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"淄博市","项目编号":"19-5-14-3700-255","项目名称":"淄博市博物馆数字化利用保护项目","保护单位名称":"淄博市博物馆","补助范围":"可移动文物保护","补助事项":"数字化保护","安排金额（万元）":"230","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"淄博市","项目编号":"19-5-12-3700-295","项目名称":"王士禛纪念馆可移动文物预防性保护项目","保护单位名称":"王士禛纪念馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"80","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"淄博市","项目编号":"19-5-12-3700-264","项目名称":"淄博市陶瓷博物馆可移动文物预防性 保护","保护单位名称":"淄博中国陶瓷馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"180","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"淄博市","项目编号":"19-5-12-3700-303","项目名称":"齐文化博物馆馆藏文物预防性保护项目","保护单位名称":"齐国故城遗址博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"380","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"淄博市","项目编号":"19-5-12-3700-264","项目名称":"淄博市陶瓷博物馆可移动文物预防性 保护","保护单位名称":"淄博中国陶瓷馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"400","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"淄博市","项目编号":"19-5-12-3700-307","项目名称":"临淄中国古车博物馆馆藏文物预防性保护项目","保护单位名称":"临淄中国古车博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"100","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"淄博市","项目编号":"19-1-02-3700-327","项目名称":"青城文昌阁修缮工程","保护单位名称":"青城文昌阁","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"210","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"淄博市","项目编号":"19-1-02-3700-251","项目名称":"颜文姜祠建筑油饰彩画保护项目","保护单位名称":"颜文姜祠","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"386","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"淄博市","项目编号":"19-1-03-3700-384","项目名称":"淄博矿业集团德日建筑群安防工程","保护单位名称":"淄博矿业集团德日建筑群","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"144","保护单位类型":"近现代","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"淄博市","项目编号":"19-1-03-3700-383","项目名称":"寨里窑址安防工程","保护单位名称":"寨里窑址","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"138","保护单位类型":"古遗址","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"淄博市","项目编号":"19-7-02-3700-403","项目名称":"周村天主堂修缮保护工程","保护单位名称":"周村天主堂","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"40","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"淄博市","项目编号":"19-7-02-3700-254","项目名称":" 原山碧霞元君行宫保护修缮工程（二期）","保护单位名称":"原山碧霞元君祠","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"120","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"淄博市","项目编号":"19-7-02-3700-399","项目名称":"周村古商业街－－大街105号、万顺街2号院修缮保护工程","保护单位名称":"周村古商业街","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"100","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"淄博市","项目编号":"19-7-02-3700-400","项目名称":"万家建筑群－1、4、5号院保护修缮工程","保护单位名称":"万家建筑群","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"100","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"淄博市","项目编号":"19-7-03-3700-408","项目名称":"毕自严故居安防工程","保护单位名称":"毕自严故居","补助范围":"省级及省级以下文物保护单位保护","补助事项":"安防","安排金额（万元）":"84","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2020","市级":"淄博市","项目编号":"19-7-02-3700-402","项目名称":"炳灵公庙修缮保护工程","保护单位名称":"炳灵公庙","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"50","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"滨州市","项目编号":"19-5-12-3700-019","项目名称":"博兴县博物馆馆藏文物预防性保护项目","保护单位名称":"博兴县博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"290","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"滨州市","项目编号":"19-5-13-3700-078","项目名称":"滨州市博物馆馆藏书画保护","保护单位名称":"滨州市博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"124","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"滨州市","项目编号":"19-5-13-3700-146","项目名称":"滨州市无棣吴坛故居修缮工程","保护单位名称":"无棣县文物管理局","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"99","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"滨州市","项目编号":"19-1-30-3700-224","项目名称":"龙华寺遗址保护规划及方案编制项目","保护单位名称":"龙华寺遗址","补助范围":"全国重点文物保护单位保护","补助事项":"保护规划编制","安排金额（万元）":"49","保护单位类型":"古遗址","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"滨州市","项目编号":"19-7-02-3700-085","项目名称":"博兴县王文抗战旧址保护修缮工程（一期）","保护单位名称":"王文抗战旧址","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"324","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"德州市","项目编号":"19-4-15-3700-242","项目名称":"岳庄空城遗址考古调查项目","保护单位名称":"山东省文物考古研究所","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"116","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"德州市","项目编号":"19-4-15-3700-243","项目名称":"窦冢遗址考古勘探项目","保护单位名称":"山东省文物考古研究所","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"43","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"德州市","项目编号":"19-4-15-3700-379","项目名称":"苏禄王墓保护工程前期经费","保护单位名称":"苏禄王墓","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"180","保护单位类型":"古墓葬","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"德州市","项目编号":"19-4-15-3700-244","项目名称":"小城子坡高唐故城考古勘探项目","保护单位名称":"山东省文物考古研究所","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"89","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"德州市","项目编号":"19-4-15-3700-377","项目名称":"于家店无棣故城考古勘探项目","保护单位名称":"山东省文物考古研究所","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"50","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"菏泽市","项目编号":"19-5-13-3700-065","项目名称":"山东曹县商都博物馆馆藏陶瓷器保护修复","保护单位名称":"曹县商都博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"79","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"菏泽市","项目编号":"1-03-17-3700-047","项目名称":"白佛山石窟造像安防工程","保护单位名称":"白佛山石窟造像","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"195","保护单位类型":"石窟寺及石刻","项目类别":"重点"},
    {"资金安排年度":"2019","市级":"菏泽市","项目编号":"19-7-02-3700-189","项目名称":"湖西人民会议厅旧址抢救性保护工程","保护单位名称":"湖西人民会议厅旧址","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"81","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"菏泽市","项目编号":"19-7-02-3700-081","项目名称":"定陶战役指挥部旧址保护维修工程（一期）","保护单位名称":"定陶战役指挥部旧址 ","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"103","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"济南市","项目编号":"4-13-19-3700-003","项目名称":"琅琊台遗址考古","保护单位名称":"琅琊台遗址","补助范围":"考古发掘","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"72","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2019","市级":"济南市","项目编号":"19-5-13-3700-003","项目名称":"济南市博物馆馆藏金属文物保护修复","保护单位名称":"济南市博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"115","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"济南市","项目编号":"19-1-02-3700-093","项目名称":"于汉济北王墓—双乳山汉墓加固保护工程","保护单位名称":"汉济北王墓","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"562","保护单位类型":"古墓葬","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"济南市","项目编号":"19-7-02-3700-151","项目名称":"平阴县学文庙大成殿、文昌阁、乡贤祠保护修缮工程","保护单位名称":"平阴文庙","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"92","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"济南市","项目编号":"19-7-02-3700-089","项目名称":"大峰山古建筑群保护修缮工程","保护单位名称":"大峰山古建筑群","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"398","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"济南市","项目编号":"19-7-02-3700-082","项目名称":"莱芜茶业口摩云山梯田保护维修工程一期","保护单位名称":"茶业口摩云山梯田群","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"180","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"济南市","项目编号":"19-7-02-3700-152","项目名称":"平阴县东峪南崖建筑群关帝庙、文昌阁等建筑修缮保护工程","保护单位名称":"东峪南崖建筑群 ","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"148","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"济南市","项目编号":"19-7-02-3700-164","项目名称":"济南章丘区刁镇孟氏古楼修缮保护工程","保护单位名称":"孟氏古楼","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"169","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"济南市","项目编号":"3-03-18-3700-001","项目名称":"孔林消防工程","保护单位名称":"曲阜孔庙及孔府","补助范围":"世界文化遗产","补助事项":"消防","安排金额（万元）":"1357","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2019","市级":"济宁市","项目编号":"19-4-15-3700-233","项目名称":"春城堌堆遗址考古勘探项目","保护单位名称":"山东省文物考古研究所","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"40","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"济宁市","项目编号":"19-4-15-3700-245","项目名称":"邾国故城保护范围内峄山街村考古调查及保护方案编制","保护单位名称":"邾国故城","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"120","保护单位类型":"古遗址","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"济宁市","项目编号":"4-13-19-3700-007","项目名称":"山东省淄博市临淄齐故城遗址","保护单位名称":"临淄齐国故城","补助范围":"考古发掘","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"105","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2019","市级":"济宁市","项目编号":"19-5-13-3700-053","项目名称":"山东济宁市博物馆馆藏春秋青铜附耳蹄足蟠螭纹鼎（N2858）保护修复项目","保护单位名称":"济宁市博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"13","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"济宁市","项目编号":"19-5-12-3700-115","项目名称":"邹城博物馆库房文物预防性保护","保护单位名称":"邹城博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"248","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"济宁市","项目编号":"19-5-12-3700-137","项目名称":"济宁市兖州区博物馆可移动文物预防性保护","保护单位名称":"兖州市博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"254","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"济宁市","项目编号":"19-5-13-3700-052","项目名称":"山东济宁市博物馆馆藏春秋青铜附耳蹄足蟠螭纹鼎（N2857）保护修复项目","保护单位名称":"济宁市博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"7","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"济宁市","项目编号":"19-5-13-3700-027","项目名称":"济宁市博物馆馆藏纸质文物保护修复","保护单位名称":"济宁市博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"132","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"济宁市","项目编号":"19-5-12-3700-071","项目名称":"济宁市博物馆可移动文物预防性保护","保护单位名称":"济宁市博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"468","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"济宁市","项目编号":"19-5-13-3700-199","项目名称":"邹城市博物馆馆藏青铜文物保护修复项目","保护单位名称":"邹城博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"111","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"济宁市","项目编号":"19-1-02-3700-197","项目名称":"大运河--刘氏祠堂过厅修缮保护工程","保护单位名称":"大运河","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"72","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"济宁市","项目编号":"19-1-05-3700-106","项目名称":"伏羲庙消防工程","保护单位名称":"伏羲庙","补助范围":"全国重点文物保护单位保护","补助事项":"消防","安排金额（万元）":"152","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"济宁市","项目编号":"19-1-02-3700-020","项目名称":"曲阜孔庙大中门至奎文阁建筑群修缮保护工程","保护单位名称":"曲阜孔庙及孔府","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"1271","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"济宁市","项目编号":"19-1-04-3700-104","项目名称":"伏羲庙防雷工程","保护单位名称":"伏羲庙","补助范围":"全国重点文物保护单位保护","补助事项":"防雷","安排金额（万元）":"20","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"济宁市","项目编号":"19-1-03-3700-076","项目名称":"嘉祥武氏墓群石刻安防升级改造工程设计方案","保护单位名称":"嘉祥武氏墓群石刻","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"72","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"济宁市","项目编号":"19-1-03-3700-103","项目名称":"泗水卞桥安防工程","保护单位名称":"卞桥","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"22","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"济宁市","项目编号":"19-1-02-3700-118","项目名称":"明鲁王墓（鲁荒王陵-朱檀墓和戈妃墓）防渗治理工程","保护单位名称":"明鲁王墓","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"717","保护单位类型":"古墓葬","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"济宁市","项目编号":"19-1-05-3700-209","项目名称":"孟林消防工程","保护单位名称":"孟林","补助范围":"全国重点文物保护单位保护","补助事项":"消防","安排金额（万元）":"219","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"济宁市","项目编号":"19-1-02-3700-380","项目名称":"尼山孔庙彩画保护工程前期经费","保护单位名称":"尼山孔庙和书院","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"200","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"济宁市","项目编号":"19-1-03-3700-105","项目名称":"伏羲庙安防工程","保护单位名称":"伏羲庙","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"120","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"济宁市","项目编号":"1-02-18-3700-013","项目名称":"山东长清灵岩寺石刻及千佛殿部分罗汉像保护修复（一期）","保护单位名称":"灵岩寺","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"600","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2019","市级":"济宁市","项目编号":"19-7-02-3700-182","项目名称":"兖州侵华日军观象台修缮保护工程","保护单位名称":"兖州侵华日军观象台","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"61","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"莱芜市","项目编号":"19-4-15-3700-225","项目名称":"汶阳遗址考古勘探项目","保护单位名称":"济南市考古研究所","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"45","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"莱芜市","项目编号":"19-5-02-3700-047","项目名称":"山东省莱芜市博物馆馆藏金属文物保护修复","保护单位名称":"莱芜博物馆","补助范围":"可移动文物保护","补助事项":"文物本体维修保护","安排金额（万元）":"240","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"莱芜市","项目编号":"19-5-13-3700-046","项目名称":"莱芜市博物馆馆藏古籍文物保护修复项目","保护单位名称":"莱芜博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"69","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"莱芜市","项目编号":"19-5-12-3700-139","项目名称":"莱芜市博物馆可移动文物预防性保护","保护单位名称":"莱芜博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"300","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"聊城市","项目编号":"19-1-03-3700-100","项目名称":"兴国寺塔安防工程","保护单位名称":"兴国寺塔","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"57","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"聊城市","项目编号":"19-1-05-3700-090","项目名称":"临清运河钞关古建筑群消防工程","保护单位名称":"临清运河钞关","补助范围":"全国重点文物保护单位保护","补助事项":"消防","安排金额（万元）":"457","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"聊城市","项目编号":"19-1-03-3700-075","项目名称":"光岳楼安防工程设计方案","保护单位名称":"光岳楼","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"35","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"临沂市","项目编号":"19-4-15-3700-236","项目名称":"金山汉墓群考古勘探项目","保护单位名称":"山东省文物考古研究所","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"59","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"临沂市","项目编号":"19-4-15-3700-235","项目名称":"陈官庄遗址考古勘探项目","保护单位名称":"山东省文物考古研究所","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"50","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"临沂市","项目编号":"19-4-15-3700-237","项目名称":"新城遗址考古勘探项目","保护单位名称":"山东省文物考古研究所","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"50","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"临沂市","项目编号":"19-5-13-3700-068","项目名称":"山东兰陵县博物馆馆藏书画保护","保护单位名称":"兰陵县博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"115","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"临沂市","项目编号":"19-5-12-3700-029","项目名称":"山东省沂水县博物馆可移动文物预防性保护项目","保护单位名称":"沂水县博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"216","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"临沂市","项目编号":"19-1-02-3700-121","项目名称":"郯国故城西城墙南段、东城墙北段现存墙体保护修缮","保护单位名称":"郯国故城","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"159","保护单位类型":"古遗址","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"临沂市","项目编号":"19-1-03-3700-091","项目名称":"南武城故城遗址安防工程项目","保护单位名称":"南武城故城遗址","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"205","保护单位类型":"古遗址","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"临沂市","项目编号":"19-1-02-3700-172","项目名称":"山东省莒南县八路军一一五师司令部旧址修缮工程","保护单位名称":"八路军一一五师司令部旧址","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"168","保护单位类型":"近现代","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"日照市","项目编号":"4-13-19-3700-005","项目名称":"山东省日照市苏家村遗址考古发掘","保护单位名称":"苏家村遗址","补助范围":"考古发掘","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"87","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2019","市级":"日照市","项目编号":"19-5-13-3700-059","项目名称":"山东五莲县博物馆馆藏金属文物保护修复","保护单位名称":"五莲县博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"130","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"日照市","项目编号":"19-5-13-3700-042","项目名称":"山东莒县博物馆馆藏书画保护修复","保护单位名称":"莒县博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"83","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"日照市","项目编号":"19-7-02-3700-083","项目名称":"山东军区机关驻地旧址修缮保护工程","保护单位名称":"山东军区机关驻地旧址","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"283","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"泰安市","项目编号":"19-4-15-3700-234","项目名称":"北桥墓群考古勘探项目","保护单位名称":"山东省文物考古研究所","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"40","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"泰安市","项目编号":"19-5-12-3700-004","项目名称":"山东泰安市博物馆可移动文物预防性保护","保护单位名称":"泰安市博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"479","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"泰安市","项目编号":"19-1-04-3700-120","项目名称":"泰山古建筑群—后石坞防雷工程","保护单位名称":"泰山古建筑群","补助范围":"全国重点文物保护单位保护","补助事项":"防雷","安排金额（万元）":"97","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"泰安市","项目编号":"19-1-03-3700-136","项目名称":"洪顶山摩崖石刻安防工程","保护单位名称":"洪顶山摩崖","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"130","保护单位类型":"石窟寺及石刻","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"泰安市","项目编号":"19-1-03-3700-116","项目名称":"棘梁山石刻安防工程","保护单位名称":"棘梁山石刻","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"173","保护单位类型":"石窟寺及石刻","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"泰安市","项目编号":"1-02-18-3700-007","项目名称":"嘉祥武氏墓群石刻本体保护","保护单位名称":"嘉祥武氏墓群石刻","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"342","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2019","市级":"泰安市","项目编号":"19-7-05-3700-157","项目名称":"泰山玉泉寺消防工程","保护单位名称":"泰山玉泉寺","补助范围":"省级及省级以下文物保护单位保护","补助事项":"消防","安排金额（万元）":"180","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"泰安市","项目编号":"19-7-05-3700-166","项目名称":"泰山竹林寺消防工程","保护单位名称":"竹林寺","补助范围":"省级及省级以下文物保护单位保护","补助事项":"消防","安排金额（万元）":"177","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"泰安市","项目编号":"19-7-02-3700-155","项目名称":"泰西抗日武装起义旧址修缮工程（二期）","保护单位名称":"泰西抗日武装起义旧址","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"70","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"泰安市","项目编号":"3-03-18-3700-006","项目名称":"泰山古建筑群-碧霞祠防雷工程","保护单位名称":"泰山古建筑群","补助范围":"世界文化遗产","补助事项":"防雷","安排金额（万元）":"53","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2019","市级":"威海市","项目编号":"19-5-13-3700-043","项目名称":"山东荣成博物馆藏瓷器文物保护修复项目","保护单位名称":"荣成博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"149","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"潍坊市","项目编号":"19-4-15-3700-232","项目名称":"赵旺铺遗址考古勘探项目","保护单位名称":"山东省文物考古研究所","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"40","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"潍坊市","项目编号":"4-13-18-3700-007","项目名称":"2018年度山东庙岛群岛海域水下考古调查","保护单位名称":"山东省水下考古研究中心","补助范围":"考古发掘","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"156","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2019","市级":"潍坊市","项目编号":"4-13-18-3700-008","项目名称":"山东省东营市垦利县胜坨镇海北遗址考古发掘","保护单位名称":"山东省水下考古研究中心","补助范围":"考古发掘","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"108","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2019","市级":"潍坊市","项目编号":"19-5-14-3700-063","项目名称":"青州市博物馆馆藏文物数字化保护及利用","保护单位名称":"青州市博物馆","补助范围":"可移动文物保护","补助事项":"数字化保护","安排金额（万元）":"180","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"潍坊市","项目编号":"19-5-13-3700-060","项目名称":"寿光市博物馆馆藏纸质文物保护修复","保护单位名称":"寿光市博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"161","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"潍坊市","项目编号":"19-5-13-3700-058","项目名称":"山东昌乐县博物馆馆藏青铜器保护修复","保护单位名称":"昌乐县博物馆 ","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"127","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"潍坊市","项目编号":"19-5-13-3700-188","项目名称":"山东省潍坊市博物馆馆藏纸质类书画修复","保护单位名称":"潍坊市博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"90","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"潍坊市","项目编号":"19-5-13-3700-066","项目名称":"山东陈介祺故居陈列馆馆藏纸质文物保护修复","保护单位名称":"陈介祺故居陈列馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"55","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"潍坊市","项目编号":"19-5-13-3700-193","项目名称":"山东昌乐博物馆馆藏古籍保护修复","保护单位名称":"昌乐县博物馆 ","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"123","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"潍坊市","项目编号":"19-5-12-3700-094","项目名称":"青州市博物馆可移动文物预防性保护","保护单位名称":"青州市博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"265","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"潍坊市","项目编号":"19-1-02-3700-126","项目名称":"坊子德日建筑群——万和楼修缮工程","保护单位名称":"坊子德日建筑群","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"123","保护单位类型":"近现代","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"潍坊市","项目编号":"19-1-03-3700-208","项目名称":"衡王府石坊安防工程","保护单位名称":"衡王府石坊","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"35","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"潍坊市","项目编号":"19-1-02-3700-119","项目名称":"坊子德日建筑群-德国领事馆办事处、日本宪兵队旧址修缮工程","保护单位名称":"坊子德日建筑群","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"353","保护单位类型":"近现代","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"潍坊市","项目编号":"1-02-18-3700-012","项目名称":"大运河中河台儿庄（月河）段驳岸修缮工程","保护单位名称":"大运河","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"711","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2019","市级":"潍坊市","项目编号":"1-04-18-3700-010","项目名称":"十笏园展示工程","保护单位名称":"十笏园","补助范围":"全国重点文物保护单位保护","补助事项":"陈列展示","安排金额（万元）":"400","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2019","市级":"潍坊市","项目编号":"19-7-02-3700-178","项目名称":"潍坊昌邑市奎聚街道傅振邦故居修缮保护工程","保护单位名称":"傅振邦故居（含墓园）","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"189","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"潍坊市","项目编号":"19-7-05-3700-187","项目名称":"城隍庙消防工程","保护单位名称":"潍城城隍庙","补助范围":"省级及省级以下文物保护单位保护","补助事项":"消防","安排金额（万元）":"130","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"烟台市","项目编号":"19-4-15-3700-231","项目名称":"城子顶遗址考古勘探项目","保护单位名称":"山东省文物考古研究所","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"42","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"烟台市","项目编号":"19-5-12-3700-177","项目名称":"烟台王懿荣纪念馆馆藏文物预防性保护","保护单位名称":"王懿荣纪念馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"370","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"烟台市","项目编号":"19-5-13-3700-198","项目名称":"烟台市博物馆馆藏纸质文物保护修复","保护单位名称":"烟台市博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"63","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"烟台市","项目编号":"19-1-04-3700-099","项目名称":"蓬莱阁防雷工程项目","保护单位名称":"蓬莱水城及蓬莱阁","补助范围":"全国重点文物保护单位保护","补助事项":"防雷","安排金额（万元）":"365","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"烟台市","项目编号":"19-7-02-3700-086","项目名称":"曲松龄旧居保护修缮工程","保护单位名称":"曲松龄旧居","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"181","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"枣庄市","项目编号":"19-5-13-3700-196","项目名称":"山东滕州汉画像石馆馆藏汉画像石保护修复工程","保护单位名称":"滕州市汉画像石馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"278","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"枣庄市","项目编号":"19-5-13-3700-062","项目名称":"枣庄市博物馆馆藏瓷器文物保护修复","保护单位名称":"枣庄市博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"76","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"枣庄市","项目编号":"19-5-12-3700-061","项目名称":"枣庄市博物馆可移动文物预防性保护","保护单位名称":"枣庄市博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"372","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"枣庄市","项目编号":"19-5-13-3700-163","项目名称":"滕州市汉画像石馆陶瓷文物保护修复工程","保护单位名称":"滕州市汉画像石馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"125","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"枣庄市","项目编号":"19-5-12-3700-050","项目名称":"山东滕州市汉画像石馆馆藏文物预防性保护","保护单位名称":"滕州市汉画像石馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"283","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"枣庄市","项目编号":"19-1-03-3700-101","项目名称":"中陈郝窑址安防工程","保护单位名称":"中陈郝窑址","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"286","保护单位类型":"古遗址","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"枣庄市","项目编号":"1-03-18-3700-005","项目名称":"程家沟古墓安防工程","保护单位名称":"程家沟古墓","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"70","保护单位类型":"古墓葬","项目类别":"重点"},
    {"资金安排年度":"2019","市级":"直属","项目编号":"19-4-15-3700-070","项目名称":"日照东海峪等遗址资料整理项目","保护单位名称":"东海峪遗址","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"24","保护单位类型":"古遗址","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"直属","项目编号":"19-4-15-3700-069","项目名称":"《龙口归城》图书出版","保护单位名称":"归城城址","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"66","保护单位类型":"古遗址","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"直属","项目编号":"19-4-15-3700-222","项目名称":"日照东港区水下考古调查项目","保护单位名称":"山东省水下考古研究中心","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"50","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"直属","项目编号":"19-4-15-3700-219","项目名称":"山东省水下文化遗产保护区域划定工程（刘公岛及相关海域第一批水下文化遗产保护区域划定项目）","保护单位名称":"山东省水下考古研究中心","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"72","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"直属","项目编号":"19-4-15-3700-221","项目名称":"山东省古港口遗址调查（渤海沿岸海港调查）项目","保护单位名称":"山东省水下考古研究中心","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"61","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"直属","项目编号":"19-4-15-3700-223","项目名称":"山东省明清海防遗址调查研究项目","保护单位名称":"山东省水下考古研究中心","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"261","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"直属","项目编号":"19-4-15-3700-122","项目名称":"邹城市峄山镇邾国故城遗址西岗墓地一号墓发掘","保护单位名称":"邾国故城","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"210","保护单位类型":"古遗址","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"直属","项目编号":"4-13-19-3700-002","项目名称":"定陶王墓地（王陵）M2汉墓考古项目","保护单位名称":"定陶王墓地（王陵）","补助范围":"考古发掘","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"618","保护单位类型":"古墓葬","项目类别":"重点"},
    {"资金安排年度":"2019","市级":"直属","项目编号":"4-13-19-3700-008","项目名称":"2019年山东东营市利津县利津铁门关遗址发掘","保护单位名称":"山东省水下考古研究中心","补助范围":"考古发掘","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"81","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2019","市级":"直属","项目编号":"4-13-19-3700-009","项目名称":"山东省威海市威海湾一号沉船水下考古工作项目","保护单位名称":"山东省水下考古研究中心","补助范围":"考古发掘","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"310","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2019","市级":"直属","项目编号":"4-13-18-3700-002","项目名称":"山东滕州大韩东周墓地考古发掘","保护单位名称":"大韩墓地","补助范围":"考古发掘","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"233","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2019","市级":"直属","项目编号":"4-13-19-3700-006","项目名称":"山东省日照市尧王城遗址考古发掘","保护单位名称":"尧王城遗址","补助范围":"考古发掘","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"73","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2019","市级":"直属","项目编号":"4-13-19-3700-004","项目名称":"山东省邹城市西岗墓地二号墓发掘","保护单位名称":"邾国故城","补助范围":"考古发掘","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"213","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2019","市级":"直属","项目编号":"19-5-13-3700-041","项目名称":"山东曲阜九龙山汉墓出土青铜器保护修复","保护单位名称":"山东省文物考古研究所","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"104","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"直属","项目编号":"19-5-13-3700-095","项目名称":"山东临朐山旺古生物化石博物馆馆藏佛教石造像保护修复项目","保护单位名称":"山东临朐山旺古生物化石博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"278","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"直属","项目编号":"19-5-13-3700-049","项目名称":"山东省滕州市大韩东周墓葬出土青铜器（一期)保护修复","保护单位名称":"山东省文物考古研究所","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"152","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"直属","项目编号":"19-5-14-3700-217","项目名称":"山东博物馆馆藏文物数字化保护项目","保护单位名称":"山东博物馆","补助范围":"可移动文物保护","补助事项":"数字化保护","安排金额（万元）":"723","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"直属","项目编号":"19-5-13-3700-048","项目名称":"山东省滕州市大韩东周墓葬出土彩绘陶器保护修复","保护单位名称":"山东省文物考古研究所","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"74","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"直属","项目编号":"19-5-12-3700-055","项目名称":"山东博物馆馆藏文物预防性保护项目","保护单位名称":"山东博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"636","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"直属","项目编号":"19-1-05-3700-114","项目名称":"万字会旧址（济南）消防工程","保护单位名称":"万字会旧址","补助范围":"全国重点文物保护单位保护","补助事项":"消防","安排金额（万元）":"426","保护单位类型":"近现代","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"直属","项目编号":"19-1-03-3700-097","项目名称":"万字会旧址（济南）安防工程","保护单位名称":"万字会旧址","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"461","保护单位类型":"近现代","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"直属","项目编号":"1-02-17-3700-061","项目名称":"日建坊子电力学校修缮工程","保护单位名称":"坊子德日建筑群","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"215","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2019","市级":"直属","项目编号":"1-02-17-3700-062","项目名称":"日建坊子电灯公司修缮工程","保护单位名称":"坊子德日建筑群","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"239","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2019","市级":"直属","项目编号":"1-02-18-3700-013","项目名称":"灵岩寺石刻及千佛殿部分罗汉像保护修复（一期）","保护单位名称":"灵岩寺","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"460","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2019","市级":"淄博市","项目编号":"19-4-15-3700-226","项目名称":"西坡地窑址考古勘探项目","保护单位名称":"山东省文物考古研究所","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"52","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"淄博市","项目编号":"19-4-15-3700-228","项目名称":"狄城遗址考古勘探项目","保护单位名称":"山东省文物考古研究所","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"60","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"淄博市","项目编号":"19-4-15-3700-227","项目名称":"巩家坞窑址考古勘探","保护单位名称":"山东省文物考古研究所","补助范围":"考古","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"35","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"淄博市","项目编号":"19-5-12-3700-039","项目名称":"蒲松龄纪念馆可移动文物预防性保护项目","保护单位名称":"蒲松龄纪念馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"126","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"淄博市","项目编号":"19-1-04-3700-110","项目名称":"青城文昌阁防雷工程","保护单位名称":"青城文昌阁","补助范围":"全国重点文物保护单位保护","补助事项":"防雷","安排金额（万元）":"28","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"淄博市","项目编号":"19-1-03-3700-107","项目名称":"青城文昌阁安防工程","保护单位名称":"青城文昌阁","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"27","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"淄博市","项目编号":"19-1-02-3700-113","项目名称":"临淄齐国故城东周墓殉马坑保护展示项目","保护单位名称":"临淄齐国故城","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"893","保护单位类型":"古遗址","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"淄博市","项目编号":"19-1-05-3700-109","项目名称":"核准青城文昌阁消防工程","保护单位名称":"青城文昌阁","补助范围":"全国重点文物保护单位保护","补助事项":"消防","安排金额（万元）":"73","保护单位类型":"古建筑","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"淄博市","项目编号":"19-1-03-3700-102","项目名称":"磁村瓷窑址安防工程","保护单位名称":"磁村瓷窑址","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"149","保护单位类型":"古遗址","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"淄博市","项目编号":"1-03-18-3700-008","项目名称":"山东省济南市长清区灵岩寺防雷工程","保护单位名称":"灵岩寺","补助范围":"全国重点文物保护单位保护","补助事项":"防雷","安排金额（万元）":"173","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2019","市级":"淄博市","项目编号":"19-7-02-3700-088","项目名称":"淄博市淄川区罗圈村八路军医院旧址（二期）建筑修缮保护工程","保护单位名称":"罗圈八路军医院旧址","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"285","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"淄博市","项目编号":"19-7-02-3700-170","项目名称":"黑铁山起义指挥部旧址维修保护工程","保护单位名称":"黑铁山起义指挥部旧址","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"82","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"淄博市","项目编号":"19-7-02-3700-202","项目名称":"原山碧霞元君祠修缮保护工程","保护单位名称":"原山碧霞元君祠","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"381","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2019","市级":"淄博市","项目编号":"19-7-02-3700-230","项目名称":"沂源栖真观本体修缮和壁画修复工程","保护单位名称":"栖真观","补助范围":"省级及省级以下文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"129","保护单位类型":"","项目类别":"一般"},
    {"资金安排年度":"2018","市级":"滨州市","项目编号":"5-12-18-3700-021","项目名称":"“菏泽百宝”文物数字化保护","保护单位名称":"菏泽市博物馆","补助范围":"可移动文物保护","补助事项":"数字化保护","安排金额（万元）":"274","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"菏泽市","项目编号":"2-02-17-3700-015","项目名称":"刘公岛甲午战争纪念地——公所后炮台修缮工程","保护单位名称":"刘公岛甲午战争纪念地","补助范围":"大遗址","补助事项":"文物本体维修保护","安排金额（万元）":"332","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"菏泽市","项目编号":"2-02-16-3700-015","项目名称":"刘公岛甲午战争纪念地——北洋海军基地后勤保障设施（屯煤所、鱼雷修理厂）","保护单位名称":"刘公岛甲午战争纪念地","补助范围":"大遗址","补助事项":"文物本体维修保护","安排金额（万元）":"1232","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"菏泽市","项目编号":"5-12-18-3700-018","项目名称":"潍坊市博物馆文物数字化保护","保护单位名称":"潍坊市博物馆","补助范围":"可移动文物保护","补助事项":"数字化保护","安排金额（万元）":"458","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"菏泽市","项目编号":"1-03-17-3700-002","项目名称":"山东省滨州魏氏庄园古建筑防雷保护工程","保护单位名称":"魏氏庄园","补助范围":"全国重点文物保护单位保护","补助事项":"防雷","安排金额（万元）":"84","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"菏泽市","项目编号":"1-02-17-3700-058","项目名称":"明德王墓地6号墓陵园东垣墙北段、北垣墙保护修缮","保护单位名称":"明德王墓地","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"143","保护单位类型":"古墓葬","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"济南市","项目编号":"5-10-18-3700-005","项目名称":"威海市文登区博物馆可移动文物预防性保护","保护单位名称":"威海市文登区博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"245","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"济南市","项目编号":"5-10-18-3700-008","项目名称":"济南市历城区博物馆可移动文物预防性保护","保护单位名称":"历城区博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"190","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"济南市","项目编号":"1-03-17-3700-047","项目名称":"白佛山石窟造像安防工程","保护单位名称":"白佛山石窟造像","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"195","保护单位类型":"石窟寺及石刻","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"济南市","项目编号":"3-03-18-3700-002","项目名称":"泰山古建筑群-青帝宫防雷工程","保护单位名称":"泰山古建筑群","补助范围":"世界文化遗产","补助事项":"防雷","安排金额（万元）":"39","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"济南市","项目编号":"3-03-17-3700-006","项目名称":"泰山古建筑群消防工程","保护单位名称":"泰山古建筑群","补助范围":"世界文化遗产","补助事项":"消防","安排金额（万元）":"1376","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"济南市","项目编号":"3-02-17-3700-011","项目名称":"齐长城博山风门道关段保护","保护单位名称":"长城——齐长城遗址","补助范围":"世界文化遗产","补助事项":"文物本体维修保护","安排金额（万元）":"310","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"济南市","项目编号":"2-03-17-3700-008","项目名称":"聊城山陕会馆安防工程","保护单位名称":"聊城山陕会馆","补助范围":"世界文化遗产","补助事项":"安防","安排金额（万元）":"72","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"济宁市","项目编号":"2-08-17-3700-010","项目名称":"临淄齐故城宫殿遗址北区（张皇路以北）环境整治工程","保护单位名称":"临淄齐国故城","补助范围":"大遗址","补助事项":"环境治理","安排金额（万元）":"880","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"济宁市","项目编号":"4-13-14-3700-015","项目名称":"定陶王墓地及2号墓内部布局考古物探","保护单位名称":"定陶王墓地（王陵）","补助范围":"考古发掘","补助事项":"保护规划和方案编制","安排金额（万元）":"201","保护单位类型":"古墓葬","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"济宁市","项目编号":"5-11-18-3700-013","项目名称":"中国甲午战争博物院藏1883-1904年济远舰主锚锚链（00281）保护修复","保护单位名称":"中国甲午战争博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"14","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"济宁市","项目编号":"5-10-17-3700-009","项目名称":"淄博市博物馆可移动文物预防性保护","保护单位名称":"淄博市博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"60","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"济宁市","项目编号":"5-11-17-3700-003","项目名称":"山东潍坊市寒亭区文物保管所所藏书画保护修复","保护单位名称":"潍坊市寒亭区文物保管所","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"33","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"济宁市","项目编号":"5-10-18-3700-007","项目名称":"山东临沂市博物馆可移动文物预防性保护","保护单位名称":"临沂市博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"499","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"济宁市","项目编号":"1-02-17-3700-063","项目名称":"巨野文庙大成殿屋面修缮工程","保护单位名称":"巨野文庙大成殿","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"117","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"济宁市","项目编号":"1-02-17-3700-054","项目名称":"灵岩寺碑刻、千佛殿部分泥塑罗汉像保护","保护单位名称":"灵岩寺","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"120","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"济宁市","项目编号":"1-02-17-3700-065","项目名称":"萧大亨墓地石刻文物保护维修工程","保护单位名称":"萧大亨墓地石刻","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"217","保护单位类型":"石窟寺及石刻","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"济宁市","项目编号":"1-03-17-3700-004","项目名称":"烟台福建会馆消防工程","保护单位名称":"烟台福建会馆","补助范围":"全国重点文物保护单位保护","补助事项":"消防","安排金额（万元）":"36","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"济宁市","项目编号":"1-03-17-3700-042","项目名称":"台儿庄大战旧址消防工程","保护单位名称":"台儿庄大战旧址","补助范围":"全国重点文物保护单位保护","补助事项":"消防","安排金额（万元）":"87","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"济宁市","项目编号":"1-02-17-3700-048","项目名称":"葛山摩崖石刻本体保护修缮","保护单位名称":"葛山和峄山摩崖","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"102","保护单位类型":"石窟寺及石刻","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"济宁市","项目编号":"1-02-17-3700-015","项目名称":"陈庄-唐口遗址保护展示二期工程---重点发掘区展示工程","保护单位名称":"陈庄--唐口遗址","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"194","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"济宁市","项目编号":"1-03-18-3700-003","项目名称":"尼山孔庙和书院消防工程","保护单位名称":"尼山孔庙和书院","补助范围":"全国重点文物保护单位保护","补助事项":"消防","安排金额（万元）":"865","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"济宁市","项目编号":"1-03-18-3700-001","项目名称":"孟母林墓群消防工程","保护单位名称":"孟母林墓群","补助范围":"全国重点文物保护单位保护","补助事项":"消防","安排金额（万元）":"900","保护单位类型":"古墓葬","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"济宁市","项目编号":"1-03-17-3700-024","项目名称":"前掌大遗址安防工程","保护单位名称":"前掌大遗址","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"104","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"济宁市","项目编号":"1-02-17-3700-016","项目名称":"陈庄-唐口遗址文物保护展示二期工程---非重点发掘区保护展示工程","保护单位名称":"陈庄--唐口遗址","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"129","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"济宁市","项目编号":"2-02-17-3700-005","项目名称":"临清运河钞关维修工程（二期）","保护单位名称":"临清运河钞关","补助范围":"世界文化遗产","补助事项":"文物本体维修保护","安排金额（万元）":"77","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"济宁市","项目编号":"3-02-17-3700-010","项目名称":"泰山古建筑群—后石坞庙修缮工程","保护单位名称":"泰山古建筑群","补助范围":"世界文化遗产","补助事项":"文物本体维修保护","安排金额（万元）":"590","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"济宁市","项目编号":"3-03-18-3700-004","项目名称":"泰山古建筑群-壶天閣防雷工程","保护单位名称":"泰山古建筑群","补助范围":"世界文化遗产","补助事项":"防雷","安排金额（万元）":"33","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"济宁市","项目编号":"3-02-17-3700-007","项目名称":"齐长城遗址诸城史家夼北山段修缮保护","保护单位名称":"长城——齐长城遗址","补助范围":"世界文化遗产","补助事项":"文物本体维修保护","安排金额（万元）":"195","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"济宁市","项目编号":"3-03-18-3700-006","项目名称":"泰山古建筑群-碧霞祠防雷工程","保护单位名称":"泰山古建筑群","补助范围":"世界文化遗产","补助事项":"防雷","安排金额（万元）":"53","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"莱芜市","项目编号":"5-12-18-3700-017","项目名称":"高密市博物馆珍贵文物数字化保护","保护单位名称":"高密市博物馆","补助范围":"可移动文物保护","补助事项":"数字化保护","安排金额（万元）":"273","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"莱芜市","项目编号":"5-10-18-3700-015","项目名称":"孔子博物馆可移动文物预防性保护","保护单位名称":"孔子博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"2501","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"莱芜市","项目编号":"1-03-17-3700-020","项目名称":"铁山、岗山摩崖石刻（含葛山、峄山摩崖石刻）安防工程","保护单位名称":"铁山、岗山摩崖石刻","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"48","保护单位类型":"石窟寺及石刻","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"聊城市","项目编号":"5-11-18-3700-011","项目名称":"中国甲午战争博物院藏1883-1904年济远舰系缆桩（00278）保护修复方案","保护单位名称":"中国甲午战争博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"19","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"聊城市","项目编号":"1-03-18-3700-002","项目名称":"周公庙消防工程","保护单位名称":"周公庙","补助范围":"全国重点文物保护单位保护","补助事项":"消防","安排金额（万元）":"381","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"聊城市","项目编号":"1-04-18-3700-010","项目名称":"十笏园展示工程","保护单位名称":"十笏园","补助范围":"全国重点文物保护单位保护","补助事项":"陈列展示","安排金额（万元）":"544","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"临沂市","项目编号":"1-27-17-3700-067","项目名称":"《大辛庄——2003年度发掘报告》出版","保护单位名称":"大辛庄遗址","补助范围":"大遗址","补助事项":"报告出版","安排金额（万元）":"75","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"临沂市","项目编号":"3-03-17-3700-001","项目名称":"曲阜孔庙及孔府消防工程","保护单位名称":"曲阜孔庙及孔府","补助范围":"世界文化遗产","补助事项":"消防","安排金额（万元）":"628","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"日照市","项目编号":"1-03-17-3700-045","项目名称":"莱芜战役指挥所旧址消防工程","保护单位名称":"莱芜战役指挥所旧址","补助范围":"全国重点文物保护单位保护","补助事项":"消防","安排金额（万元）":"83","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"日照市","项目编号":"1-03-18-3700-008","项目名称":"山东省济南市长清区灵岩寺防雷工程","保护单位名称":"灵岩寺","补助范围":"全国重点文物保护单位保护","补助事项":"防雷","安排金额（万元）":"172","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"日照市","项目编号":"1-02-16-3700-054","项目名称":"北沟头遗址抢险加固工程","保护单位名称":"北沟头遗址","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"215","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"日照市","项目编号":"1-02-15-3700-043","项目名称":"台儿庄大战旧址清真古寺修缮工程","保护单位名称":"台儿庄大战旧址","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"21","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"泰安市","项目编号":"2-08-17-3700-009","项目名称":"两城镇遗址环境整治工程","保护单位名称":"两城镇遗址","补助范围":"大遗址","补助事项":"环境治理","安排金额（万元）":"2171","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"泰安市","项目编号":"2-02-17-3700-007","项目名称":"大汶口遗址汶河北岸遗址展示系统工程","保护单位名称":"大汶口遗址","补助范围":"大遗址","补助事项":"文物本体维修保护","安排金额（万元）":"102","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"泰安市","项目编号":"4-13-18-3700-004","项目名称":"博兴龙华寺遗址2018年度发掘工作方案","保护单位名称":"龙华寺遗址","补助范围":"考古发掘","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"96","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"泰安市","项目编号":"5-11-17-3700-010","项目名称":"潍坊市博物馆馆藏金属文物保护修复","保护单位名称":"潍坊市博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"47","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"泰安市","项目编号":"5-10-17-3700-008","项目名称":"山东高密市博物馆可移动文物预防性保护","保护单位名称":"高密市博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"101","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"泰安市","项目编号":"5-11-18-3700-010","项目名称":"中国甲午战争博物院藏1883-1904年济远舰系缆桩（00277）保护修复","保护单位名称":"中国甲午战争博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"19","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"泰安市","项目编号":"1-03-17-3700-011","项目名称":"烟台福建会馆安全防范工程","保护单位名称":"烟台福建会馆","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"110","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"泰安市","项目编号":"1-02-18-3700-006","项目名称":"烟台山近代建筑群--顺昌商行旧址修缮工程","保护单位名称":"烟台山近代建筑群","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"201","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"泰安市","项目编号":"1-02-17-3700-064","项目名称":"颜文姜祠建筑油饰彩画保护","保护单位名称":"颜文姜祠","补助范围":"全国重点文物保护单位保护","补助事项":"保护规划和方案编制","安排金额（万元）":"39","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"泰安市","项目编号":"1-03-17-3700-031","项目名称":"烟台山近代建筑群安全防范工程","保护单位名称":"烟台山近代建筑群","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"139","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"泰安市","项目编号":"1-03-16-3700-052","项目名称":"牟氏庄园消防工程","保护单位名称":"牟氏庄园","补助范围":"全国重点文物保护单位保护","补助事项":"消防","安排金额（万元）":"138","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"泰安市","项目编号":"1-02-17-3700-014","项目名称":"陈庄 -口遗址保护展示二期工程---重点发掘区祭坛、墓葬、马坑本体保护工程","保护单位名称":"陈庄--唐口遗址","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"133","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"泰安市","项目编号":"1-02-17-3700-051","项目名称":"曾庙寝殿修缮","保护单位名称":"曾庙","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"12","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"泰安市","项目编号":"1-02-17-3700-012","项目名称":"平阴永济桥修缮保护工程","保护单位名称":"平阴永济桥","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"136","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"泰安市","项目编号":"1-03-17-3700-029","项目名称":"山东省青州真教寺古建筑防雷保护工程","保护单位名称":"青州真教寺","补助范围":"全国重点文物保护单位保护","补助事项":"防雷","安排金额（万元）":"39","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"泰安市","项目编号":"3-03-17-3700-003","项目名称":"曲阜孔庙文物建筑防雷工程","保护单位名称":"曲阜孔庙及孔府","补助范围":"世界文化遗产","补助事项":"防雷","安排金额（万元）":"279","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"威海市","项目编号":"2-02-17-3700-014","项目名称":"明鲁王墓（鲁荒王陵-朱檀墓和戈妃墓）防渗治理工程","保护单位名称":"明鲁王墓","补助范围":"大遗址","补助事项":"文物本体维修保护","安排金额（万元）":"115","保护单位类型":"古墓葬","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"威海市","项目编号":"5-12-18-3700-016","项目名称":"烟台市博物馆文物数字化保护","保护单位名称":"烟台市博物馆","补助范围":"可移动文物保护","补助事项":"数字化保护","安排金额（万元）":"707","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"威海市","项目编号":"5-11-18-3700-012","项目名称":"山东巨野县文物管理所馆藏东汉镂空龙纹铜炉保护修复方案","保护单位名称":"巨野县博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"11","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"威海市","项目编号":"1-02-17-3700-055","项目名称":"兴隆塔地宫修缮工程","保护单位名称":"兴隆塔","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"50","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"威海市","项目编号":"1-02-17-3700-049","项目名称":"兖州天主教堂修缮工程","保护单位名称":"兖州天主教堂","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"102","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"威海市","项目编号":"3-02-18-3700-007","项目名称":"泰山古建筑群-万仙楼修缮工程","保护单位名称":"泰山古建筑群","补助范围":"世界文化遗产","补助事项":"文物本体维修保护","安排金额（万元）":"256","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"潍坊市","项目编号":"2-03-17-3700-003","项目名称":"大汶口遗址（宁阳县部分）安防工程","保护单位名称":"大汶口遗址","补助范围":"大遗址","补助事项":"安防","安排金额（万元）":"98","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"潍坊市","项目编号":"2-01-17-3700-017","项目名称":"大汶口遗址北岸保护展示工程","保护单位名称":"大汶口遗址","补助范围":"大遗址","补助事项":"保护规划和方案编制","安排金额（万元）":"35","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"潍坊市","项目编号":"4-13-18-3700-005","项目名称":"牡丹区马岭岗镇青邱堌堆遗址考古发掘","保护单位名称":"马岭岗镇青邱堌堆遗址","补助范围":"考古发掘","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"140","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"潍坊市","项目编号":"1-03-17-3700-025","项目名称":"龙泉塔安防工程","保护单位名称":"龙泉塔","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"57","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"潍坊市","项目编号":"1-03-17-3700-010","项目名称":"尧王城遗址安防工程","保护单位名称":"尧王城遗址","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"102","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"潍坊市","项目编号":"2-03-17-3700-004","项目名称":"两城镇遗址安防工程","保护单位名称":"两城镇遗址","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"57","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"潍坊市","项目编号":"1-03-17-3700-017","项目名称":"村里集城址及墓群安防工程","保护单位名称":"村里集城址及墓群","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"73","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"潍坊市","项目编号":"1-03-17-3700-035","项目名称":"孟府孟庙消防工程","保护单位名称":"孟庙、孟府和孟林（孟庙及孟府）","补助范围":"全国重点文物保护单位保护","补助事项":"消防","安排金额（万元）":"108","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"潍坊市","项目编号":"1-04-17-3700-066","项目名称":"坊子德日建筑群部分建筑展示工程前期费用","保护单位名称":"坊子德日建筑群","补助范围":"全国重点文物保护单位保护","补助事项":"陈列布展","安排金额（万元）":"120","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"潍坊市","项目编号":"1-02-17-3700-050","项目名称":"孟庙、孟府和孟林修缮工程（第三期）","保护单位名称":"孟庙、孟府和孟林（孟庙及孟府）","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"120","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"潍坊市","项目编号":"1-03-17-3700-008","项目名称":"曲阜颜庙古建筑防雷保护工程","保护单位名称":"颜庙","补助范围":"全国重点文物保护单位保护","补助事项":"防雷","安排金额（万元）":"111","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"潍坊市","项目编号":"3-03-18-3700-003","项目名称":"泰山古建筑群-孔子庙防雷工程","保护单位名称":"泰山古建筑群","补助范围":"世界文化遗产","补助事项":"防雷","安排金额（万元）":"51","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"烟台市","项目编号":"2-07-17-3700-016","项目名称":"临淄齐国故城遄台遗址保护展示","保护单位名称":"临淄齐国故城","补助范围":"大遗址","补助事项":"规划设计方案编制","安排金额（万元）":"50","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"烟台市","项目编号":"4-13-18-3700-006","项目名称":"山东省日照市两城镇遗址发掘","保护单位名称":"两城镇遗址","补助范围":"大遗址","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"130","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"烟台市","项目编号":"2-03-17-3700-001","项目名称":"刘公岛甲午战争纪念地防雷工程","保护单位名称":"刘公岛甲午战争纪念地","补助范围":"大遗址","补助事项":"防雷","安排金额（万元）":"228","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"烟台市","项目编号":"5-11-18-3700-014","项目名称":"中国甲午战争博物院藏1883-1904年济远舰海水淡化蒸汽发生罐（00124）保护修复","保护单位名称":"中国甲午战争博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"65","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"烟台市","项目编号":"1-03-17-3700-033","项目名称":"颜庙消防工程","保护单位名称":"颜庙","补助范围":"全国重点文物保护单位保护","补助事项":"消防","安排金额（万元）":"155","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"烟台市","项目编号":"1-02-17-3700-056","项目名称":"萧王庄墓群墓二、四号墓抢险加固工程","保护单位名称":"萧王庄墓群","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"100","保护单位类型":"古墓葬","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"烟台市","项目编号":"1-03-17-3700-030","项目名称":"丁氏故宅防雷工程","保护单位名称":"丁氏故宅","补助范围":"全国重点文物保护单位保护","补助事项":"防雷","安排金额（万元）":"103","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"烟台市","项目编号":"1-03-17-3700-005","项目名称":"宁阳颜子庙和颜林安防工程","保护单位名称":"宁阳颜子庙和颜林","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"50","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"烟台市","项目编号":"1-03-17-3700-009","项目名称":"北沈遗址安防工程","保护单位名称":"北沈遗址","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"77","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"烟台市","项目编号":"2-02-17-3700-013","项目名称":"大运河中河台儿庄（月河）段安全防护栏加固工程","保护单位名称":"大运河（京杭大运河）","补助范围":"世界文化遗产","补助事项":"文物本体维修保护","安排金额（万元）":"345","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"烟台市","项目编号":"3-02-17-3700-008","项目名称":"齐长城—莱芜锦阳关段抢修保护工程","保护单位名称":"长城——齐长城遗址","补助范围":"世界文化遗产","补助事项":"文物本体维修保护","安排金额（万元）":"303","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"枣庄市","项目编号":"5-11-17-3700-001","项目名称":"济南市刘家庄遗址出土青铜器保护修复","保护单位名称":"济南市考古研究所","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"67","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"枣庄市","项目编号":"5-10-18-3700-003","项目名称":"荣成博物馆可移动文物预防性保护","保护单位名称":"荣成博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"281","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"枣庄市","项目编号":"1-02-17-3700-043","项目名称":"北庄遗址展示与利用工程","保护单位名称":"北庄遗址","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"145","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"枣庄市","项目编号":"1-03-17-3700-032","项目名称":"杞国故城遗址（古墓葬部分）安防工程","保护单位名称":"杞国故城遗址","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"93","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"枣庄市","项目编号":"1-02-17-3700-053","项目名称":"汉济北王墓——双乳山汉墓加固保护","保护单位名称":"汉济北王墓","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"71","保护单位类型":"古墓葬","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"枣庄市","项目编号":"3-03-17-3700-002","项目名称":"曲阜孔府文物建筑防雷保护工程","保护单位名称":"曲阜孔庙及孔府","补助范围":"世界文化遗产","补助事项":"防雷","安排金额（万元）":"288","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"枣庄市","项目编号":"3-07-17-3700-009","项目名称":"曲阜孔庙排水系统整修及展示提升工程","保护单位名称":"曲阜孔庙及孔府","补助范围":"世界文化遗产","补助事项":"规划设计方案编制","安排金额（万元）":"50","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"直属","项目编号":"2-04-16-3700-016","项目名称":"城子崖遗址西城垣保护展示厅改建工程","保护单位名称":"城子崖遗址","补助范围":"大遗址","补助事项":"陈列布展","安排金额（万元）":"265","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"直属","项目编号":"2-03-17-3700-006","项目名称":"薛城遗址安防工程","保护单位名称":"薛城遗址","补助范围":"大遗址","补助事项":"安防","安排金额（万元）":"85","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"直属","项目编号":"2-02-17-3700-011","项目名称":"临淄齐国故城10号宫殿建筑基址展示工程","保护单位名称":"临淄齐国故城","补助范围":"大遗址","补助事项":"本体或载体的维修保护","安排金额（万元）":"229","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"直属","项目编号":"2-02-17-3700-002","项目名称":"曲阜鲁国故城东城墙（含城壕）中段遗迹保护展示工程","保护单位名称":"曲阜鲁国故城","补助范围":"大遗址","补助事项":"文物本体维修保护","安排金额（万元）":"130","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"直属","项目编号":"4-13-18-3700-003","项目名称":"山东省菏泽市定陶区何楼遗址考古发掘","保护单位名称":"菏泽市定陶区何楼遗址","补助范围":"考古发掘","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"69","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"直属","项目编号":"5-10-18-3700-004","项目名称":"菏泽市博物馆可移动文物预防性保护","保护单位名称":"菏泽市博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"433","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"直属","项目编号":"5-12-18-3700-020","项目名称":"成武县博物馆珍贵文物数字化保护","保护单位名称":"成武县博物馆","补助范围":"可移动文物保护","补助事项":"数字化保护","安排金额（万元）":"132","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"直属","项目编号":"5-11-17-3700-006","项目名称":"山东日照市博物馆馆藏书画保护修复","保护单位名称":"日照市博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"34","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"直属","项目编号":"5-12-18-3700-019","项目名称":"枣庄市博物馆珍贵文物数字化保护","保护单位名称":"枣庄市博物馆","补助范围":"可移动文物保护","补助事项":"数字化保护","安排金额（万元）":"200","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"直属","项目编号":"5-11-17-3700-004","项目名称":"山东肥城市文物管理所金属文物保护修复","保护单位名称":"肥城市文物管理所","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"52","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"直属","项目编号":"1-02-17-3700-007","项目名称":"淄博矿业集团德日建筑群修缮工程","保护单位名称":"淄博矿业集团德日建筑群","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"225","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"直属","项目编号":"1-03-17-3700-034","项目名称":"淄博矿业集团德日建筑群消防工程","保护单位名称":"淄博矿业集团德日建筑群","补助范围":"全国重点文物保护单位保护","补助事项":"消防","安排金额（万元）":"35","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"直属","项目编号":"1-03-18-3700-009","项目名称":"戚继光祠堂消防工程","保护单位名称":"戚继光祠堂及戚继光墓","补助范围":"全国重点文物保护单位保护","补助事项":"消防","安排金额（万元）":"78","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"直属","项目编号":"1-03-15-3700-118","项目名称":"新四军军部暨华东军区、华东野战军诞生地旧址消防工程","保护单位名称":"新四军军部暨华东军区、华东野战军诞生地旧址","补助范围":"全国重点文物保护单位保护","补助事项":"消防","安排金额（万元）":"52","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"直属","项目编号":"1-01-17-3700-057","项目名称":"东海峪遗址本体围护","保护单位名称":"东海峪遗址","补助范围":"全国重点文物保护单位保护","补助事项":"保护规划和方案编制","安排金额（万元）":"50","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"直属","项目编号":"1-03-18-3700-005","项目名称":"程家沟古墓安防工程","保护单位名称":"程家沟古墓","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"70","保护单位类型":"古墓葬","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"直属","项目编号":"1-02-17-3700-023","项目名称":"韩氏家族墓地石刻保护工程","保护单位名称":"韩氏家族墓地","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"40","保护单位类型":"古墓葬","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"直属","项目编号":"1-03-17-3700-039","项目名称":"丁氏故宅消防工程","保护单位名称":"丁氏故宅","补助范围":"全国重点文物保护单位保护","补助事项":"消防","安排金额（万元）":"88","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"直属","项目编号":"1-03-17-3700-013","项目名称":"蒲松龄故宅安防工程","保护单位名称":"蒲松龄故宅","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"76","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"直属","项目编号":"1-03-17-3700-028","项目名称":"嬴城遗址安防工程","保护单位名称":"嬴城遗址","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"132","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"直属","项目编号":"3-03-17-3700-004","项目名称":"曲阜孔庙及孔府安防升级改造工程","保护单位名称":"曲阜孔庙及孔府","补助范围":"世界文化遗产","补助事项":"安防","安排金额（万元）":"430","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"直属","项目编号":"3-02-15-3700-037","项目名称":"齐长城博山区两平至西沟峪北山段抢救性保护工程","保护单位名称":"长城——齐长城遗址","补助范围":"世界文化遗产","补助事项":"文物本体维修保护","安排金额（万元）":"240","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"直属","项目编号":"3-03-18-3700-005","项目名称":"泰山古建筑群-三阳观防雷工程","保护单位名称":"泰山古建筑群","补助范围":"世界文化遗产","补助事项":"防雷","安排金额（万元）":"56","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"直属","项目编号":"3-02-15-3700-007","项目名称":"齐长城—响水崖子南山段长城、邹家沟东山段长城修缮保护","保护单位名称":"长城——齐长城遗址","补助范围":"世界文化遗产","补助事项":"文物本体维修保护","安排金额（万元）":"135","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"直属","项目编号":"3-03-18-3700-001","项目名称":"孔林消防工程","保护单位名称":"孔林","补助范围":"世界文化遗产","补助事项":"消防","安排金额（万元）":"1356","保护单位类型":"古墓葬","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"直属","项目编号":"3-02-18-3700-009","项目名称":"泰山古建筑群-红门宫修缮工程","保护单位名称":"泰山古建筑群","补助范围":"世界文化遗产","补助事项":"文物本体维修保护","安排金额（万元）":"369","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"淄博市","项目编号":"4-13-18-3700-001","项目名称":"章丘市城子崖遗址考古发掘","保护单位名称":"城子崖遗址","补助范围":"大遗址","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"101","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"淄博市","项目编号":"4-13-18-3700-002","项目名称":"山东滕州大韩东周墓地考古发掘","保护单位名称":"大韩墓地","补助范围":"考古发掘","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"374","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"淄博市","项目编号":"5-10-18-3700-006","项目名称":"山东济南市博物馆可移动文物预防性保护","保护单位名称":"济南市博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"426","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"淄博市","项目编号":"5-11-17-3700-005","项目名称":"山东曲阜孔府文物档案馆青铜器保护修复","保护单位名称":"曲阜孔府文物档案馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"42","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"淄博市","项目编号":"5-10-17-3700-011","项目名称":"潍坊市博物馆可移动文物预防性保护","保护单位名称":"潍坊市博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"120","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"淄博市","项目编号":"5-10-18-3700-001","项目名称":"寿光市博物馆可移动文物预防性保护","保护单位名称":"寿光市博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"495","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"淄博市","项目编号":"5-11-18-3700-009","项目名称":"中国甲午战争博物院藏1883-1904年济远舰系缆桩（00276）保护修复方案","保护单位名称":"中国甲午战争博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护（含文物本体修复）","安排金额（万元）":"19","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"淄博市","项目编号":"1-03-17-3700-018","项目名称":"田齐王陵（青州市部分）安防工程","保护单位名称":"田齐王陵","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"134","保护单位类型":"古墓葬","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"淄博市","项目编号":"1-03-18-3700-004","项目名称":"崔芬墓安防工程","保护单位名称":"崔芬墓","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"86","保护单位类型":"古墓葬","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"淄博市","项目编号":"1-03-17-3700-046","项目名称":"孟府与孟庙防雷工程","保护单位名称":"孟庙、孟府和孟林（孟庙及孟府）","补助范围":"全国重点文物保护单位保护","补助事项":"防雷","安排金额（万元）":"646","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"淄博市","项目编号":"1-03-17-3700-027","项目名称":"留村石墓群安防工程","保护单位名称":"留村石墓群","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"35","保护单位类型":"古墓葬","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"淄博市","项目编号":"1-02-17-3700-052","项目名称":"济南纬二路近现代建筑群德华银行旧址修缮","保护单位名称":"济南纬二路近现代建筑群","补助范围":"全国重点文物保护单位保护","补助事项":"保护规划和方案编制","安排金额（万元）":"102","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2018","市级":"淄博市","项目编号":"3-02-18-3700-008","项目名称":"泰山古建筑群-碧霞祠（一期）修缮工程","保护单位名称":"泰山古建筑群","补助范围":"世界文化遗产","补助事项":"文物本体维修保护","安排金额（万元）":"687","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"滨州市","项目编号":"2-03-16-3700-008","项目名称":"汉鲁王墓（曲阜部分）安防工程","保护单位名称":"曲阜鲁国故城","补助范围":"大遗址","补助事项":"安防","安排金额（万元）":"50","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"滨州市","项目编号":"5-11-17-3700-006","项目名称":"山东日照市博物馆馆藏书画保护修复项目","保护单位名称":"日照市博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护修复","安排金额（万元）":"77","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"滨州市","项目编号":"1-02-14-3700-122","项目名称":"南北瓮城、东北角及西南角城墙、点将台遗迹抢救性保护工程","保护单位名称":"萧城遗址","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"526","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"菏泽市","项目编号":"5-10-16-3700-011","项目名称":"莒县博物馆可移动文物预防性保护","保护单位名称":"莒县博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"84","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"济南市","项目编号":"2-03-17-3700-004","项目名称":"两城镇遗址安防工程","保护单位名称":"两城镇遗址","补助范围":"大遗址","补助事项":"安防","安排金额（万元）":"130","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"济南市","项目编号":"4-13-17-3700-002","项目名称":"山东省济南市章丘区焦家遗址考古发掘","保护单位名称":"焦家遗址","补助范围":"考古发掘","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"175","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"济南市","项目编号":"5-10-16-3700-010","项目名称":"日照市博物馆可移动文物预防性保护","保护单位名称":"日照市博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"30","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"济南市","项目编号":"1-02-17-3700-026","项目名称":"青州衡王府石坊抢险加固保护方案","保护单位名称":"衡王府石坊","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"72","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"济南市","项目编号":"1-03-17-3700-003","项目名称":"烟台福建会馆防雷工程","保护单位名称":"烟台福建会馆","补助范围":"全国重点文物保护单位保护","补助事项":"防雷","安排金额（万元）":"68","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"济南市","项目编号":"1-02-15-3700-013","项目名称":"孟庙、孟府、孟林修缮工程（二期）","保护单位名称":"孟庙、孟府和孟林（孟庙及孟府）","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"40","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"济宁市","项目编号":"2-03-17-3700-003","项目名称":"大汶口遗址（宁阳县部分）安防工程","保护单位名称":"大汶口遗址","补助范围":"大遗址","补助事项":"安防","安排金额（万元）":"227","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"济宁市","项目编号":"2-16-15-3700-012","项目名称":"东平陵故城东城墙维修加固工程","保护单位名称":"城子崖遗址","补助范围":"大遗址","补助事项":"本体或载体的维修保护","安排金额（万元）":"116","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"济宁市","项目编号":"4-13-16-3700-004","项目名称":"2016年度庙岛群岛南五岛海域水下考古工作","保护单位名称":"山东省水下考古研究中心","补助范围":"考古发掘","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"35","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"济宁市","项目编号":"4-13-16-3700-007","项目名称":"狄城遗址考古发掘","保护单位名称":"狄城遗址","补助范围":"考古发掘","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"21","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"济宁市","项目编号":"5-11-17-3700-005","项目名称":"山东曲阜孔府文物档案馆青铜器保护修复项目","保护单位名称":"山东曲阜孔府文物档案馆","补助范围":"可移动文物保护","补助事项":"文物技术保护修复","安排金额（万元）":"97","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"济宁市","项目编号":"5-10-16-3700-013","项目名称":"东平县博物馆可移动文物预防性保护","保护单位名称":"东平县博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"43","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"济宁市","项目编号":"5-11-17-3700-003","项目名称":"山东潍坊市寒亭区文物保管所所藏书画保护修复项目","保护单位名称":"潍坊市寒亭区文物保管所","补助范围":"可移动文物保护","补助事项":"文物技术保护修复","安排金额（万元）":"74","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"济宁市","项目编号":"1-03-16-3700-004","项目名称":"萧大亨墓地石刻安全防范工程","保护单位名称":"萧大亨墓地石刻","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"61","保护单位类型":"石窟寺及石刻","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"济宁市","项目编号":"1-03-16-3700-036","项目名称":"尼山孔庙和书院安防工程","保护单位名称":"尼山孔庙和书院","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"58","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"济宁市","项目编号":"1-03-17-3700-020","项目名称":"铁山、岗山摩崖石刻（含葛山、峄山摩崖石刻）安防工程","保护单位名称":"铁山、岗山摩崖石刻","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"112","保护单位类型":"石窟寺及石刻","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"济宁市","项目编号":"1-02-17-3700-015","项目名称":"陈庄-唐口遗址保护展示二期工程---重点发掘区展示工程","保护单位名称":"陈庄--唐口遗址","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"450","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"济宁市","项目编号":"1-03-17-3700-009","项目名称":"北沈遗址安防工程","保护单位名称":"北沈遗址","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"179","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"济宁市","项目编号":"1-03-16-3700-014","项目名称":"嘉祥县曾庙消防工程","保护单位名称":"曾庙","补助范围":"全国重点文物保护单位保护","补助事项":"消防","安排金额（万元）":"26","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"济宁市","项目编号":"1-02-17-3700-016","项目名称":"陈庄-唐口遗址文物保护展示二期工程---非重点发掘区保护展示工程","保护单位名称":"陈庄--唐口遗址","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"301","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"济宁市","项目编号":"1-03-16-3700-012","项目名称":"嘉祥县青山寺消防系统工程","保护单位名称":"青山寺","补助范围":"全国重点文物保护单位保护","补助事项":"消防","安排金额（万元）":"42","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"济宁市","项目编号":"1-01-16-3700-050","项目名称":"昌邑故城保护规划编制","保护单位名称":"昌邑故城址","补助范围":"全国重点文物保护单位保护","补助事项":"保护规划和方案编制","安排金额（万元）":"120","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"济宁市","项目编号":"1-03-17-3700-017","项目名称":"村里集城址及墓群安防工程","保护单位名称":"村里集城址及墓群","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"170","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"济宁市","项目编号":"1-02-15-3700-023","项目名称":"烟台山近代建筑群烟台芝罘俱乐部修缮工程","保护单位名称":"烟台山近代建筑群","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"175","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"济宁市","项目编号":"1-02-16-3700-015","项目名称":"山东嘉祥青山寺附属建筑、总体院落围墙保护修缮工程","保护单位名称":"青山寺","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"92","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"济宁市","项目编号":"1-02-17-3700-012","项目名称":"平阴永济桥修缮保护工程","保护单位名称":"平阴永济桥","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"317","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"济宁市","项目编号":"1-03-17-3700-008","项目名称":"曲阜颜庙古建筑防雷保护工程","保护单位名称":"颜庙","补助范围":"全国重点文物保护单位保护","补助事项":"防雷","安排金额（万元）":"258","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"济宁市","项目编号":"1-03-16-3700-051","项目名称":"莱芜战役指挥所旧址安防工程","保护单位名称":"莱芜战役指挥所旧址","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"57","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"济宁市","项目编号":"1-03-16-3700-032","项目名称":"嘴子前墓群安全防范工程","保护单位名称":"嘴子前墓群","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"46","保护单位类型":"古墓葬","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"济宁市","项目编号":"1-03-16-3700-006","项目名称":"明德王墓地安全防范工程","保护单位名称":"明德王墓地","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"26","保护单位类型":"古墓葬","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"济宁市","项目编号":"3-02-15-3700-037","项目名称":"齐长城博山区两平至西沟峪北山段抢救性保护工程","保护单位名称":"长城","补助范围":"世界文化遗产","补助事项":"文物本体维修保护","安排金额（万元）":"560","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"济宁市","项目编号":"2-03-16-3700-007","项目名称":"聊城山陕会馆古建筑防雷保护工程","保护单位名称":"大运河","补助范围":"世界文化遗产","补助事项":"防雷","安排金额（万元）":"29","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"济宁市","项目编号":"3-02-16-3700-006","项目名称":"曲阜孔庙西路古建筑群修缮工程","保护单位名称":"曲阜孔庙及孔府","补助范围":"世界文化遗产","补助事项":"文物本体维修保护","安排金额（万元）":"178","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"济宁市","项目编号":"2-02-16-3700-011","项目名称":"阳谷段河工设施陶城铺闸、水门桥、张秋运河石桥修缮保护工程","保护单位名称":"大运河","补助范围":"世界文化遗产","补助事项":"文物本体维修保护","安排金额（万元）":"99","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"莱芜市","项目编号":"2-02-17-3700-011","项目名称":"临淄齐国故城10号宫殿建筑基址展示工程","保护单位名称":"临淄齐国故城","补助范围":"大遗址","补助事项":"文物本体维修保护","安排金额（万元）":"534","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"莱芜市","项目编号":"1-03-17-3700-033","项目名称":"颜庙消防工程","保护单位名称":"颜庙","补助范围":"全国重点文物保护单位保护","补助事项":"消防","安排金额（万元）":"359","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"聊城市","项目编号":"2-02-16-3700-006","项目名称":"刘公岛甲午战争纪念地——丁汝昌寓所修缮工程","保护单位名称":"刘公岛甲午战争纪念地","补助范围":"大遗址","补助事项":"文物本体维修保护","安排金额（万元）":"85","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"聊城市","项目编号":"2-02-17-3700-007","项目名称":"大汶口遗址汶河北岸遗址展示系统工程","保护单位名称":"大汶口遗址","补助范围":"大遗址","补助事项":"文物本体维修保护","安排金额（万元）":"236","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"聊城市","项目编号":"1-03-17-3700-024","项目名称":"前掌大遗址安防工程","保护单位名称":"前掌大遗址","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"240","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"聊城市","项目编号":"1-02-15-3700-053","项目名称":"临淄墓群淄河店二号墓保护展示工程","保护单位名称":"临淄墓群","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"856","保护单位类型":"古墓葬","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"聊城市","项目编号":"1-02-14-3700-011","项目名称":"山东省蓬莱水城关门口及部分附属城墙抢险加固","保护单位名称":"蓬莱水城及蓬莱阁","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"57","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"聊城市","项目编号":"1-03-16-3700-008","项目名称":"鄫国故城遗址文物保护安防系统工程","保护单位名称":"鄫国故城遗址","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"56","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"聊城市","项目编号":"1-03-17-3700-010","项目名称":"尧王城遗址安防工程","保护单位名称":"尧王城遗址","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"238","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"聊城市","项目编号":"3-02-16-3700-002","项目名称":"泰山古建筑群王母池修缮工程","保护单位名称":"泰山古建筑群","补助范围":"世界文化遗产","补助事项":"文物本体维修保护","安排金额（万元）":"139","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"临沂市","项目编号":"2-02-17-3700-002","项目名称":"曲阜鲁国故城东城墙（含城壕）中段遗迹保护展示工程","保护单位名称":"曲阜鲁国故城","补助范围":"大遗址","补助事项":"文物本体维修保护","安排金额（万元）":"303","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"临沂市","项目编号":"5-10-17-3700-009","项目名称":"淄博市博物馆可移动文物预防性保护项目","保护单位名称":"淄博市博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"139","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"临沂市","项目编号":"1-03-17-3700-030","项目名称":"丁氏故宅防雷工程","保护单位名称":"丁氏故宅","补助范围":"全国重点文物保护单位保护","补助事项":"防雷","安排金额（万元）":"239","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"临沂市","项目编号":"1-03-17-3700-034","项目名称":"淄博矿业集团德日建筑群消防工程","保护单位名称":"淄博矿业集团德日建筑群","补助范围":"全国重点文物保护单位保护","补助事项":"消防","安排金额（万元）":"81","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"日照市","项目编号":"1-03-17-3700-004","项目名称":"烟台福建会馆消防工程","保护单位名称":"烟台福建会馆","补助范围":"全国重点文物保护单位保护","补助事项":"消防","安排金额（万元）":"81","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"日照市","项目编号":"1-03-17-3700-002","项目名称":"山东省滨州魏氏庄园古建筑防雷保护工程","保护单位名称":"魏氏庄园","补助范围":"全国重点文物保护单位保护","补助事项":"防雷","安排金额（万元）":"195","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"日照市","项目编号":"2-02-16-3700-014","项目名称":"大运河遗产临清段临清砖闸、月径桥修缮保护工程","保护单位名称":"大运河","补助范围":"世界文化遗产","补助事项":"文物本体维修保护","安排金额（万元）":"104","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"泰安市","项目编号":"2-03-17-3700-006","项目名称":"薛城遗址安防工程","保护单位名称":"薛城遗址","补助范围":"大遗址","补助事项":"安防","安排金额（万元）":"198","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"泰安市","项目编号":"5-10-17-3700-012","项目名称":"山东博物馆珍贵文物预防性保护项目","保护单位名称":"山东博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"59","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"泰安市","项目编号":"1-03-17-3700-001","项目名称":"丈八佛安防工程","保护单位名称":"丈八佛","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"56","保护单位类型":"石窟寺及石刻","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"泰安市","项目编号":"1-02-16-3700-010","项目名称":"山东嘉祥青山寺泰山行宫保护修缮工程","保护单位名称":"青山寺","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"39","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"泰安市","项目编号":"1-03-17-3700-013","项目名称":"蒲松龄故宅安防工程","保护单位名称":"蒲松龄故宅","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"177","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"泰安市","项目编号":"1-03-17-3700-039","项目名称":"丁氏故宅消防工程","保护单位名称":"丁氏故宅","补助范围":"全国重点文物保护单位保护","补助事项":"消防","安排金额（万元）":"203","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"泰安市","项目编号":"1-01-17-3700-036","项目名称":"驼山石窟保护规划","保护单位名称":"驼山石窟","补助范围":"全国重点文物保护单位保护","补助事项":"保护规划和方案编制","安排金额（万元）":"43","保护单位类型":"石窟寺及石刻","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"泰安市","项目编号":"1-03-17-3700-042","项目名称":"台儿庄大战旧址消防工程","保护单位名称":"台儿庄大战旧址","补助范围":"全国重点文物保护单位保护","补助事项":"消防","安排金额（万元）":"201","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"泰安市","项目编号":"1-03-17-3700-006","项目名称":"周公庙防雷工程","保护单位名称":"周公庙","补助范围":"全国重点文物保护单位保护","补助事项":"防雷","安排金额（万元）":"84","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"泰安市","项目编号":"3-02-16-3700-005","项目名称":"曲阜孔庙金声玉振坊至弘道门古建筑群修缮工程","保护单位名称":"曲阜孔庙及孔府","补助范围":"世界文化遗产","补助事项":"文物本体维修保护","安排金额（万元）":"83","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"泰安市","项目编号":"3-02-16-3700-009","项目名称":"孔庙东路古建筑油饰彩画第一期修缮工程","保护单位名称":"曲阜孔庙及孔府","补助范围":"世界文化遗产","补助事项":"文物本体维修保护","安排金额（万元）":"112","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"泰安市","项目编号":"3-02-16-3700-001","项目名称":"泰山古建筑群--斗母宫修缮工程","保护单位名称":"泰山古建筑群","补助范围":"世界文化遗产","补助事项":"文物本体维修保护","安排金额（万元）":"117","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"泰安市","项目编号":"3-03-17-3700-006","项目名称":"泰山古建筑群消防工程","保护单位名称":"泰山古建筑群","补助范围":"世界文化遗产","补助事项":"消防","安排金额（万元）":"1376","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"威海市","项目编号":"2-08-15-3700-008","项目名称":"临淄齐国故城阚家寨冶铸遗址展示及环境整治工程","保护单位名称":"临淄齐国故城","补助范围":"大遗址","补助事项":"环境治理","安排金额（万元）":"858","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"威海市","项目编号":"2-03-17-3700-001","项目名称":"刘公岛甲午战争纪念地防雷工程","保护单位名称":"刘公岛甲午战争纪念地","补助范围":"大遗址","补助事项":"防雷","安排金额（万元）":"532","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"威海市","项目编号":"3-02-17-3700-007","项目名称":"齐长城遗址诸城史家夼北山段修缮保护","保护单位名称":"长城","补助范围":"世界文化遗产","补助事项":"文物本体维修保护","安排金额（万元）":"454","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"潍坊市","项目编号":"4-13-16-3700-003","项目名称":"山东东平湖水域及周围淹没区文物考古调查勘探","保护单位名称":"山东省水下考古研究中心","补助范围":"考古发掘","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"48","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"潍坊市","项目编号":"5-10-17-3700-011","项目名称":"潍坊市博物馆可移动文物预防性保护项目","保护单位名称":"潍坊市博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"280","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"潍坊市","项目编号":"5-11-17-3700-010","项目名称":"潍坊市博物馆馆藏金属文物保护修复项目","保护单位名称":"潍坊市博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护修复","安排金额（万元）":"108","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"潍坊市","项目编号":"1-03-17-3700-018","项目名称":"田齐王陵（青州市部分）安防工程","保护单位名称":"田齐王陵","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"311","保护单位类型":"古墓葬","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"潍坊市","项目编号":"1-03-16-3700-029","项目名称":"丁氏故宅安全防范工程","保护单位名称":"丁氏故宅","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"45","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"潍坊市","项目编号":"1-02-16-3700-053","项目名称":"山东省嘉祥县曾庙——宗圣殿东庑和西庑修缮工程","保护单位名称":"曾庙","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"100","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"潍坊市","项目编号":"1-02-16-3700-011","项目名称":"山东嘉祥青山寺中轴线建筑保护修缮工程","保护单位名称":"青山寺","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"51","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"潍坊市","项目编号":"1-03-12-3700-026","项目名称":"淄博市临淄区齐王陵安全技术防范系统工程","保护单位名称":"田齐王陵","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"67","保护单位类型":"古墓葬","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"潍坊市","项目编号":"3-03-17-3700-003","项目名称":"曲阜孔庙文物建筑防雷工程","保护单位名称":"曲阜孔庙及孔府","补助范围":"世界文化遗产","补助事项":"防雷","安排金额（万元）":"651","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"潍坊市","项目编号":"2-02-17-3700-005","项目名称":"临清运河钞关维修工程（二期）","保护单位名称":"大运河","补助范围":"世界文化遗产","补助事项":"文物本体维修保护","安排金额（万元）":"179","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"潍坊市","项目编号":"3-02-16-3700-004","项目名称":"泰安岱庙天贶殿及炳灵门古建筑油饰彩画保护工程","保护单位名称":"泰山古建筑群","补助范围":"世界文化遗产","补助事项":"文物本体维修保护","安排金额（万元）":"140","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"潍坊市","项目编号":"3-03-17-3700-002","项目名称":"曲阜孔府文物建筑防雷保护工程","保护单位名称":"曲阜孔庙及孔府","补助范围":"世界文化遗产","补助事项":"防雷","安排金额（万元）":"672","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"烟台市","项目编号":"2-16-15-3700-010","项目名称":"大汶口遗址汶河北岸区域已发掘房屋遗迹保护性设施工程","保护单位名称":"大汶口遗址","补助范围":"大遗址","补助事项":"本体或载体的维修保护","安排金额（万元）":"307","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"烟台市","项目编号":"2-04-16-3700-016","项目名称":"城子崖遗址西城垣保护展示厅改建工程","保护单位名称":"城子崖遗址","补助范围":"大遗址","补助事项":"保护展示","安排金额（万元）":"616","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"烟台市","项目编号":"5-10-16-3700-012","项目名称":"威海市博物馆可移动文物预防性保护","保护单位名称":"威海市博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"40","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"烟台市","项目编号":"1-03-17-3700-032","项目名称":"杞国故城遗址（古墓葬部分）安防工程","保护单位名称":"杞国故城遗址","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"217","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"烟台市","项目编号":"1-03-17-3700-011","项目名称":"烟台福建会馆安全防范工程","保护单位名称":"烟台福建会馆","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"256","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"烟台市","项目编号":"1-03-16-3700-033","项目名称":"台儿庄大战旧址安防工程","保护单位名称":"台儿庄大战旧址","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"64","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"烟台市","项目编号":"1-02-14-3700-024","项目名称":"山东省坊子德日建筑群（第一期）修缮工程","保护单位名称":"坊子德日建筑群","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"33","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"烟台市","项目编号":"1-02-15-3700-114","项目名称":"原齐鲁大学近现代建筑群-广智院修缮工程","保护单位名称":"原齐鲁大学近现代建筑群","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"73","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"烟台市","项目编号":"1-03-16-3700-016","项目名称":"新四军军部暨华东军区、华东野战军诞生地旧址安防工程","保护单位名称":"新四军军部暨华东军区、华东野战军诞生地旧址","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"87","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"烟台市","项目编号":"1-03-16-3700-052","项目名称":"牟氏庄园消防工程","保护单位名称":"牟氏庄园","补助范围":"全国重点文物保护单位保护","补助事项":"消防","安排金额（万元）":"320","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"烟台市","项目编号":"1-02-17-3700-014","项目名称":"陈庄 -口遗址保护展示二期工程---重点发掘区祭坛、墓葬、马坑本体保护工程","保护单位名称":"陈庄--唐口遗址","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"309","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"烟台市","项目编号":"1-02-17-3700-043","项目名称":"北庄遗址展示与利用工程","保护单位名称":"北庄遗址","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"338","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"烟台市","项目编号":"3-03-16-3700-010","项目名称":"孔林安防工程","保护单位名称":"曲阜孔庙及孔府","补助范围":"世界文化遗产","补助事项":"安防","安排金额（万元）":"83","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"烟台市","项目编号":"2-03-17-3700-008","项目名称":"聊城山陕会馆安防工程","保护单位名称":"大运河","补助范围":"世界文化遗产","补助事项":"安防","安排金额（万元）":"165","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"烟台市","项目编号":"3-02-15-3700-007","项目名称":"齐长城—响水崖子南山段长城、邹家沟东山段长城修缮保护","保护单位名称":"长城","补助范围":"世界文化遗产","补助事项":"文物本体维修保护","安排金额（万元）":"315","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"烟台市","项目编号":"3-03-17-3700-001","项目名称":"曲阜孔庙及孔府消防工程","保护单位名称":"曲阜孔庙及孔府","补助范围":"世界文化遗产","补助事项":"消防","安排金额（万元）":"941","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"枣庄市","项目编号":"5-11-17-3700-002","项目名称":"山东莘县文物管理所藏纺织品保护修复项目","保护单位名称":"莘县文物管理所","补助范围":"可移动文物保护","补助事项":"文物技术保护修复","安排金额（万元）":"90","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"枣庄市","项目编号":"5-11-17-3700-004","项目名称":"山东肥城市文物管理所金属文物保护修复项目","保护单位名称":"肥城市文物管理所","补助范围":"可移动文物保护","补助事项":"文物技术保护修复","安排金额（万元）":"120","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"枣庄市","项目编号":"1-03-16-3700-035","项目名称":"北寨墓群安全防范工程","保护单位名称":"北寨墓群","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"96","保护单位类型":"古墓葬","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"枣庄市","项目编号":"3-02-16-3700-003","项目名称":"泰山古建筑群-普照寺（东禅院）修缮工程","保护单位名称":"泰山古建筑群","补助范围":"世界文化遗产","补助事项":"文物本体维修保护","安排金额（万元）":"55","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"枣庄市","项目编号":"3-02-16-3700-007","项目名称":"曲阜孔府内宅门至后五间古建筑群修缮工程","保护单位名称":"曲阜孔庙及孔府","补助范围":"世界文化遗产","补助事项":"文物本体维修保护","安排金额（万元）":"417","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"枣庄市","项目编号":"2-16-14-3700-015","项目名称":"大运河遗产会通河临清段会通闸、临清闸保护修缮工程","保护单位名称":"大运河","补助范围":"世界文化遗产","补助事项":"本体或载体的维修保护","安排金额（万元）":"81","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"直属","项目编号":"2-08-14-3700-001","项目名称":"北城墙西段（孔林二林门至104国道）保护展示及环境整治工程","保护单位名称":"曲阜鲁国故城","补助范围":"大遗址","补助事项":"本体或载体的维修保护","安排金额（万元）":"169","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"直属","项目编号":"1-20-17-3700-038","项目名称":"邹平丁公遗址考古发掘报告","保护单位名称":"丁公遗址","补助范围":"考古发掘","补助事项":"考古资料整理、报告出版","安排金额（万元）":"125","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"直属","项目编号":"5-10-16-3700-017","项目名称":"山东省文物保护修复中心预防性保护","保护单位名称":"山东省文物保护修复中心","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"185","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"直属","项目编号":"5-10-17-3700-008","项目名称":"山东高密市博物馆可移动文物预防性保护项目","保护单位名称":"高密市博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"234","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"直属","项目编号":"5-11-17-3700-001","项目名称":"济南市刘家庄遗址出土青铜器保护修复项目","保护单位名称":"济南市考古研究所","补助范围":"可移动文物保护","补助事项":"文物技术保护修复","安排金额（万元）":"156","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"直属","项目编号":"1-02-17-3700-007","项目名称":"淄博矿业集团德日建筑群修缮工程","保护单位名称":"淄博矿业集团德日建筑群","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"525","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"直属","项目编号":"1-03-17-3700-025","项目名称":"龙泉塔安防工程","保护单位名称":"龙泉塔","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"133","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"直属","项目编号":"1-02-16-3700-047","项目名称":"千佛崖造像保护工程","保护单位名称":"千佛崖造像（包括龙虎塔、九顶塔）","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"70","保护单位类型":"石窟寺及石刻","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"直属","项目编号":"1-02-16-3700-043","项目名称":"坊子德日建筑群车站给水所片区修缮工程","保护单位名称":"坊子德日建筑群","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"127","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"直属","项目编号":"1-03-17-3700-035","项目名称":"孟府孟庙消防工程","保护单位名称":"孟庙、孟府和孟林（孟庙及孟府）","补助范围":"全国重点文物保护单位保护","补助事项":"消防","安排金额（万元）":"250","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"直属","项目编号":"1-03-15-3700-118","项目名称":"新四军军部暨华东军区、华东野战军诞生地旧址消防工程","保护单位名称":"新四军军部暨华东军区、华东野战军诞生地旧址","补助范围":"全国重点文物保护单位保护","补助事项":"消防","安排金额（万元）":"120","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"直属","项目编号":"1-02-17-3700-023","项目名称":"韩氏家族墓地石刻保护工程","保护单位名称":"韩氏家族墓地","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"93","保护单位类型":"古墓葬","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"直属","项目编号":"1-03-17-3700-027","项目名称":"留村石墓群安防工程","保护单位名称":"留村石墓群","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"81","保护单位类型":"古墓葬","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"直属","项目编号":"1-01-17-3700-041","项目名称":"烟台福建会馆古建筑油饰彩画保护工程","保护单位名称":"烟台福建会馆","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"70","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"直属","项目编号":"1-02-16-3700-040","项目名称":"坊子德日建筑群德日军医院修缮工程","保护单位名称":"坊子德日建筑群","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"432","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"直属","项目编号":"1-02-16-3700-042","项目名称":"坊子德日建筑群铁路机务段建筑修缮工程","保护单位名称":"坊子德日建筑群","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"558","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"直属","项目编号":"1-03-17-3700-028","项目名称":"嬴城遗址安防工程","保护单位名称":"嬴城遗址","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"307","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"直属","项目编号":"1-03-16-3700-031","项目名称":"烟台山近代建筑群防雷工程","保护单位名称":"烟台山近代建筑群","补助范围":"全国重点文物保护单位保护","补助事项":"防雷","安排金额（万元）":"60","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"直属","项目编号":"3-02-16-3700-008","项目名称":"曲阜孔府后花园古建筑群修缮工程","保护单位名称":"曲阜孔庙及孔府","补助范围":"世界文化遗产","补助事项":"文物本体维修保护","安排金额（万元）":"97","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"直属","项目编号":"3-02-16-3700-011","项目名称":"淄川区青龙山至城子段抢救性保护工程","保护单位名称":"长城","补助范围":"世界文化遗产","补助事项":"文物本体维修保护","安排金额（万元）":"70","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"直属","项目编号":"3-03-16-3700-012","项目名称":"泰山古建筑群之南天门区域防雷工程","保护单位名称":"泰山古建筑群","补助范围":"世界文化遗产","补助事项":"防雷","安排金额（万元）":"56","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"直属","项目编号":"3-03-17-3700-004","项目名称":"曲阜孔庙及孔府安防升级改造工程","保护单位名称":"曲阜孔庙及孔府","补助范围":"世界文化遗产","补助事项":"安防","安排金额（万元）":"643","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"淄博市","项目编号":"2-04-14-3700-007","项目名称":"临淄齐国故城小城城墙遗迹保护展示工程","保护单位名称":"临淄齐国故城","补助范围":"大遗址","补助事项":"本体或载体的维修保护","安排金额（万元）":"412","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"淄博市","项目编号":"4-13-15-3700-005","项目名称":"山东省日照市东港区尧王城遗址考古发掘","保护单位名称":"尧王城遗址","补助范围":"考古发掘","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"21","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"淄博市","项目编号":"1-02-16-3700-041","项目名称":"坊子德日建筑群修女楼修缮工程","保护单位名称":"坊子德日建筑群","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"71","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"淄博市","项目编号":"1-03-16-3700-021","项目名称":"孟母林墓群安防工程","保护单位名称":"孟母林墓群","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"89","保护单位类型":"古墓葬","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"淄博市","项目编号":"1-03-17-3700-029","项目名称":"山东省青州真教寺古建筑防雷保护工程","保护单位名称":"青州真教寺","补助范围":"全国重点文物保护单位保护","补助事项":"防雷","安排金额（万元）":"88","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"淄博市","项目编号":"1-03-16-3700-028","项目名称":"魏氏庄园安防工程","保护单位名称":"魏氏庄园","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"60","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"淄博市","项目编号":"1-03-17-3700-021","项目名称":"戚继光祠堂及戚继光墓（戚继光祠堂）安防工程","保护单位名称":"戚继光祠堂及戚继光墓","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"35","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"淄博市","项目编号":"1-03-17-3700-019","项目名称":"平阴县翠屏山多佛塔安防工程","保护单位名称":"翠屏山多佛塔","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"71","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"淄博市","项目编号":"1-03-16-3700-049","项目名称":"坊子德日建筑群文物消防安全工程（一期）","保护单位名称":"坊子德日建筑群","补助范围":"全国重点文物保护单位保护","补助事项":"消防","安排金额（万元）":"890","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"淄博市","项目编号":"1-02-16-3700-048","项目名称":"偪阳故城城墙遗迹抢险加固保护工程","保护单位名称":"偪阳故城","补助范围":"全国重点文物保护单位保护","补助事项":"文物本体维修保护","安排金额（万元）":"951","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"淄博市","项目编号":"1-03-17-3700-031","项目名称":"烟台山近代建筑群安全防范工程","保护单位名称":"烟台山近代建筑群","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"323","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"淄博市","项目编号":"1-03-17-3700-005","项目名称":"宁阳颜子庙和颜林安防工程","保护单位名称":"宁阳颜子庙和颜林","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"115","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"淄博市","项目编号":"3-02-16-3700-002","项目名称":"泰山古建筑群王母池修缮工程","保护单位名称":"泰山古建筑群","补助范围":"世界文化遗产","补助事项":"文物本体维修保护","安排金额（万元）":"28","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2017","市级":"淄博市","项目编号":"3-03-17-3700-005","项目名称":"泰山古建筑群之玉皇庙区域防雷工程","保护单位名称":"泰山古建筑群","补助范围":"世界文化遗产","补助事项":"防雷","安排金额（万元）":"69","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"滨州市","项目编号":"4-13-16-3700-006","项目名称":"焦家遗址考古发掘","保护单位名称":"焦家遗址","补助范围":"考古发掘","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"79","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"滨州市","项目编号":"4-13-16-3700-007","项目名称":"狄城遗址考古发掘","保护单位名称":"狄城遗址","补助范围":"考古发掘","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"81","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"滨州市","项目编号":"2-01-16-3700-010","项目名称":"大运河阳谷段保护规划编制","保护单位名称":"大运河","补助范围":"世界文化遗产","补助事项":"保护规划和方案编制","安排金额（万元）":"50","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"菏泽市","项目编号":"1-03-16-3700-013","项目名称":"嘉祥县青山寺防雷系统工程","保护单位名称":"青山寺","补助范围":"全国重点文物保护单位保护","补助事项":"防雷","安排金额（万元）":"81","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"济南市","项目编号":"1-02-14-3700-108","项目名称":"宁阳颜子庙和颜林修缮保护工程","保护单位名称":"宁阳颜子庙和颜林","补助范围":"全国重点文物保护单位保护","补助事项":"维修保护","安排金额（万元）":"43","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"济南市","项目编号":"1-02-16-3700-047","项目名称":"千佛崖造像保护工程","保护单位名称":"千佛崖造像（包括龙虎塔、九顶塔）","补助范围":"全国重点文物保护单位保护","补助事项":"维修保护","安排金额（万元）":"279","保护单位类型":"石窟寺及石刻","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"济南市","项目编号":"1-01-16-3700-039","项目名称":"杞国故城遗址文物保护规划编制","保护单位名称":"杞国故城遗址","补助范围":"全国重点文物保护单位保护","补助事项":"保护规划和方案编制","安排金额（万元）":"120","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"济南市","项目编号":"1-03-16-3700-021","项目名称":"孟母林墓群安防工程","保护单位名称":"孟母林墓群","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"353","保护单位类型":"古墓葬","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"济南市","项目编号":"1-02-16-3700-010","项目名称":"山东嘉祥青山寺泰山行宫保护修缮工程","保护单位名称":"青山寺","补助范围":"全国重点文物保护单位保护","补助事项":"维修保护","安排金额（万元）":"153","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"济宁市","项目编号":"2-01-16-3700-005","项目名称":"邾国故城展示专项规划","保护单位名称":"曲阜鲁国故城","补助范围":"大遗址","补助事项":"保护规划和方案编制","安排金额（万元）":"120","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"济宁市","项目编号":"2-02-16-3700-012","项目名称":"曲阜鲁国故城东城墙（含城壕）中段遗迹保护展示工程","保护单位名称":"曲阜鲁国故城","补助范围":"大遗址","补助事项":"维修保护","安排金额（万元）":"52","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"济宁市","项目编号":"4-13-16-3700-008","项目名称":"昌邑廒里村古沉船遗址发掘","保护单位名称":"山东省水下考古研究中心","补助范围":"考古发掘","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"93","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"济宁市","项目编号":"5-11-16-3700-003","项目名称":"曲阜鲁国故城遗址出土陶器保护修复","保护单位名称":"曲阜孔府文物档案馆","补助范围":"可移动文物保护","补助事项":"文物技术保护修复","安排金额（万元）":"25","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"济宁市","项目编号":"5-11-16-3700-008","项目名称":"菏泽古船出土文物保护修复","保护单位名称":"菏泽市博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护修复","安排金额（万元）":"196","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"济宁市","项目编号":"5-11-16-3700-005","项目名称":"济阳县博物馆馆藏金属文物保护修复","保护单位名称":"济阳县博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护修复","安排金额（万元）":"300","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"济宁市","项目编号":"5-10-16-3700-017","项目名称":"山东省文物保护修复中心预防性保护","保护单位名称":"山东省文物保护修复中心","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"740","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"济宁市","项目编号":"5-10-16-3700-010","项目名称":"日照市博物馆可移动文物预防性保护","保护单位名称":"日照市博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"119","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"济宁市","项目编号":"5-11-16-3700-009","项目名称":"乳山市文物管理所所藏金属文物保护修复","保护单位名称":"乳山市文物管理所","补助范围":"可移动文物保护","补助事项":"文物技术保护修复","安排金额（万元）":"255","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"济宁市","项目编号":"1-02-15-3700-053","项目名称":"临淄墓群淄河店二号墓保护展示工程","保护单位名称":"临淄墓群","补助范围":"全国重点文物保护单位保护","补助事项":"维修保护","安排金额（万元）":"1284","保护单位类型":"古墓葬","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"济宁市","项目编号":"1-03-14-3700-071","项目名称":"王尽美故居消防工程","保护单位名称":"王尽美故居","补助范围":"全国重点文物保护单位保护","补助事项":"消防","安排金额（万元）":"25","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"济宁市","项目编号":"1-01-16-3700-037","项目名称":"村里集城址及墓群保护规划编制","保护单位名称":"村里集城址及墓群","补助范围":"全国重点文物保护单位保护","补助事项":"保护规划和方案编制","安排金额（万元）":"136","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"济宁市","项目编号":"1-01-16-3700-003","项目名称":"蓬莱水城及蓬莱阁碑刻、戚继光牌坊加固工程","保护单位名称":"蓬莱水城及蓬莱阁","补助范围":"全国重点文物保护单位保护","补助事项":"保护规划和方案编制","安排金额（万元）":"24","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"济宁市","项目编号":"1-03-16-3700-028","项目名称":"魏氏庄园安防工程","保护单位名称":"魏氏庄园","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"236","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"济宁市","项目编号":"1-03-16-3700-036","项目名称":"尼山孔庙和书院安防工程","保护单位名称":"尼山孔庙和书院","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"228","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"济宁市","项目编号":"1-02-14-3700-133","项目名称":"微山伏羲庙修缮工程","保护单位名称":"伏羲庙","补助范围":"全国重点文物保护单位保护","补助事项":"维修保护","安排金额（万元）":"60","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"济宁市","项目编号":"1-03-16-3700-004","项目名称":"萧大亨墓地石刻安全防范工程","保护单位名称":"萧大亨墓地石刻","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"244","保护单位类型":"石窟寺及石刻","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"济宁市","项目编号":"1-02-16-3700-015","项目名称":"山东嘉祥青山寺附属建筑、总体院落围墙保护修缮工程","保护单位名称":"青山寺","补助范围":"全国重点文物保护单位保护","补助事项":"维修保护","安排金额（万元）":"366","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"济宁市","项目编号":"1-02-15-3700-043","项目名称":"台儿庄大战旧址清真古寺修缮工程","保护单位名称":"台儿庄大战旧址","补助范围":"全国重点文物保护单位保护","补助事项":"维修保护","安排金额（万元）":"96","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"济宁市","项目编号":"3-02-16-3700-003","项目名称":"泰山古建筑群-普照寺（东禅院）修缮工程","保护单位名称":"泰山古建筑群","补助范围":"世界文化遗产","补助事项":"维修保护","安排金额（万元）":"217","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"济宁市","项目编号":"3-03-16-3700-010","项目名称":"孔林安防工程","保护单位名称":"曲阜孔庙及孔府","补助范围":"世界文化遗产","补助事项":"安防","安排金额（万元）":"328","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"济宁市","项目编号":"3-02-15-3700-027","项目名称":"孔府中路古建筑群（大门至三堂）油饰彩画第二期修缮工程","保护单位名称":"曲阜孔庙及孔府","补助范围":"世界文化遗产","补助事项":"维修保护","安排金额（万元）":"73","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"济宁市","项目编号":"2-02-16-3700-011","项目名称":"阳谷段河工设施陶城铺闸、水门桥、张秋运河石桥修缮保护工程","保护单位名称":"大运河","补助范围":"世界文化遗产","补助事项":"维修保护","安排金额（万元）":"394","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"济宁市","项目编号":"3-02-16-3700-007","项目名称":"曲阜孔府内宅门至后五间古建筑群修缮工程","保护单位名称":"曲阜孔庙及孔府","补助范围":"世界文化遗产","补助事项":"维修保护","安排金额（万元）":"971","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"济宁市","项目编号":"3-02-16-3700-011","项目名称":"淄川区青龙山至城子段抢救性保护工程","保护单位名称":"长城","补助范围":"世界文化遗产","补助事项":"维修保护","安排金额（万元）":"280","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"济宁市","项目编号":"3-02-15-3700-025","项目名称":"孔林古建筑（万古长春坊碑亭、思堂院落、神庖、后土庖、孔毓圻碑楼、于氏坊）油饰彩画二期保护维修工程","保护单位名称":"曲阜孔庙及孔府","补助范围":"世界文化遗产","补助事项":"维修保护","安排金额（万元）":"35","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"聊城市","项目编号":"2-16-15-3700-012","项目名称":"东平陵故城东城墙维修加固工程","保护单位名称":"城子崖遗址","补助范围":"大遗址","补助事项":"维修保护","安排金额（万元）":"463","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"聊城市","项目编号":"4-13-15-3700-005","项目名称":"山东省日照市东港区尧王城遗址考古发掘","保护单位名称":"尧王城遗址","补助范围":"考古发掘","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"83","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"聊城市","项目编号":"1-03-12-3700-026","项目名称":"淄博市临淄区齐王陵安全技术防范系统工程","保护单位名称":"田齐王陵","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"267","保护单位类型":"古墓葬","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"聊城市","项目编号":"1-02-15-3700-114","项目名称":"原齐鲁大学近现代建筑群-广智院修缮工程","保护单位名称":"原齐鲁大学近现代建筑群","补助范围":"全国重点文物保护单位保护","补助事项":"维修保护","安排金额（万元）":"289","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"聊城市","项目编号":"1-03-16-3700-034","项目名称":"重兴塔安防系统工程","保护单位名称":"重兴塔","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"12","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"聊城市","项目编号":"1-04-14-3700-002","项目名称":"北辛遗址保护展示","保护单位名称":"北辛遗址","补助范围":"全国重点文物保护单位保护","补助事项":"陈列布展","安排金额（万元）":"26","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"聊城市","项目编号":"1-02-14-3700-122","项目名称":"南北瓮城、东北角及西南角城墙、点将台遗迹抢救性保护工程","保护单位名称":"萧城遗址","补助范围":"全国重点文物保护单位保护","补助事项":"维修保护","安排金额（万元）":"1226","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"聊城市","项目编号":"3-02-16-3700-001","项目名称":"泰山古建筑群--斗母宫修缮工程","保护单位名称":"泰山古建筑群","补助范围":"世界文化遗产","补助事项":"维修保护","安排金额（万元）":"464","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"临沂市","项目编号":"1-02-16-3700-048","项目名称":"偪阳故城城墙遗迹抢险加固保护工程","保护单位名称":"偪阳故城","补助范围":"全国重点文物保护单位保护","补助事项":"维修保护","安排金额（万元）":"1425","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"临沂市","项目编号":"3-02-16-3700-008","项目名称":"曲阜孔府后花园古建筑群修缮工程","保护单位名称":"曲阜孔庙及孔府","补助范围":"世界文化遗产","补助事项":"维修保护","安排金额（万元）":"388","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"日照市","项目编号":"1-03-16-3700-027","项目名称":"光善寺塔安防工程","保护单位名称":"光善寺塔","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"90","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"日照市","项目编号":"1-01-16-3700-025","项目名称":"杨家盐业遗址群保护规划编制","保护单位名称":"杨家盐业遗址群","补助范围":"全国重点文物保护单位保护","补助事项":"保护规划和方案编制","安排金额（万元）":"120","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"泰安市","项目编号":"2-03-16-3700-008","项目名称":"汉鲁王墓（曲阜部分）安防工程","保护单位名称":"曲阜鲁国故城","补助范围":"大遗址","补助事项":"安防","安排金额（万元）":"198","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"泰安市","项目编号":"5-12-15-3700-002","项目名称":"青州市博物馆青州历史陈列数字化保护","保护单位名称":"青州市博物馆","补助范围":"可移动文物保护","补助事项":"数字化保护","安排金额（万元）":"88","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"泰安市","项目编号":"1-02-16-3700-042","项目名称":"坊子德日建筑群铁路机务段建筑修缮工程","保护单位名称":"坊子德日建筑群","补助范围":"全国重点文物保护单位保护","补助事项":"维修保护","安排金额（万元）":"1302","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"泰安市","项目编号":"1-03-16-3700-008","项目名称":"鄫国故城遗址文物保护安防系统工程","保护单位名称":"鄫国故城遗址","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"222","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"泰安市","项目编号":"1-02-16-3700-011","项目名称":"山东嘉祥青山寺中轴线建筑保护修缮工程","保护单位名称":"青山寺","补助范围":"全国重点文物保护单位保护","补助事项":"维修保护","安排金额（万元）":"201","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"泰安市","项目编号":"1-02-14-3700-024","项目名称":"山东省坊子德日建筑群（第一期）修缮工程","保护单位名称":"坊子德日建筑群","补助范围":"全国重点文物保护单位保护","补助事项":"维修保护","安排金额（万元）":"128","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"泰安市","项目编号":"1-02-15-3700-013","项目名称":"孟庙、孟府、孟林修缮工程（二期）","保护单位名称":"孟庙、孟府和孟林（孟庙及孟府）","补助范围":"全国重点文物保护单位保护","补助事项":"维修保护","安排金额（万元）":"160","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"泰安市","项目编号":"2-03-16-3700-007","项目名称":"聊城山陕会馆古建筑防雷保护工程","保护单位名称":"大运河","补助范围":"世界文化遗产","补助事项":"防雷","安排金额（万元）":"114","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"威海市","项目编号":"1-01-15-3700-037","项目名称":"丁公遗址保护规划","保护单位名称":"丁公遗址","补助范围":"全国重点文物保护单位保护","补助事项":"保护规划和方案编制","安排金额（万元）":"92","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"潍坊市","项目编号":"2-04-14-3700-007","项目名称":"临淄齐国故城小城城墙遗迹保护展示工程","保护单位名称":"临淄齐国故城","补助范围":"大遗址","补助事项":"维修保护","安排金额（万元）":"961","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"潍坊市","项目编号":"4-13-16-3700-004","项目名称":"2016年度庙岛群岛南五岛海域水下考古工作","保护单位名称":"山东省水下考古研究中心","补助范围":"考古发掘","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"139","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"潍坊市","项目编号":"5-10-16-3700-011","项目名称":"莒县博物馆可移动文物预防性保护","保护单位名称":"莒县博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"336","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"潍坊市","项目编号":"5-10-16-3700-012","项目名称":"威海市博物馆可移动文物预防性保护","保护单位名称":"威海市博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"160","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"潍坊市","项目编号":"1-01-16-3700-022","项目名称":"大朱家村遗址保护规划编制","保护单位名称":"大朱家村遗址","补助范围":"全国重点文物保护单位保护","补助事项":"保护规划和方案编制","安排金额（万元）":"39","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"潍坊市","项目编号":"1-02-14-3700-011","项目名称":"山东省蓬莱水城关门口及部分附属城墙抢险加固","保护单位名称":"蓬莱水城及蓬莱阁","补助范围":"全国重点文物保护单位保护","补助事项":"维修保护","安排金额（万元）":"228","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"潍坊市","项目编号":"1-03-16-3700-032","项目名称":"嘴子前墓群安全防范工程","保护单位名称":"嘴子前墓群","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"181","保护单位类型":"古墓葬","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"潍坊市","项目编号":"1-03-16-3700-031","项目名称":"烟台山近代建筑群防雷工程","保护单位名称":"烟台山近代建筑群","补助范围":"全国重点文物保护单位保护","补助事项":"防雷","安排金额（万元）":"236","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"潍坊市","项目编号":"1-03-16-3700-006","项目名称":"明德王墓地安全防范工程","保护单位名称":"明德王墓地","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"100","保护单位类型":"古墓葬","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"潍坊市","项目编号":"1-02-15-3700-023","项目名称":"烟台山近代建筑群烟台芝罘俱乐部修缮工程","保护单位名称":"烟台山近代建筑群","补助范围":"全国重点文物保护单位保护","补助事项":"维修保护","安排金额（万元）":"697","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"烟台市","项目编号":"4-13-16-3700-003","项目名称":"山东东平湖水域及周围淹没区文物考古调查勘探","保护单位名称":"山东省水下考古研究中心","补助范围":"考古发掘","补助事项":"考古调查、勘探和发掘","安排金额（万元）":"189","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"烟台市","项目编号":"5-11-16-3700-015","项目名称":"莒县博物馆馆藏金属文物保护修复","保护单位名称":"莒县博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护修复","安排金额（万元）":"437","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"烟台市","项目编号":"5-11-16-3700-006","项目名称":"章丘博物馆馆藏金属文物保护修复（铁器）","保护单位名称":"章丘市博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护修复","安排金额（万元）":"79","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"烟台市","项目编号":"1-03-16-3700-033","项目名称":"台儿庄大战旧址安防工程","保护单位名称":"台儿庄大战旧址","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"256","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"烟台市","项目编号":"1-03-16-3700-049","项目名称":"坊子德日建筑群文物消防安全工程（一期）","保护单位名称":"坊子德日建筑群","补助范围":"全国重点文物保护单位保护","补助事项":"消防","安排金额（万元）":"1333","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"烟台市","项目编号":"1-02-15-3700-026","项目名称":"台儿庄大战旧址——清真古寺沐浴室修缮工程","保护单位名称":"台儿庄大战旧址","补助范围":"全国重点文物保护单位保护","补助事项":"维修保护","安排金额（万元）":"17","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"烟台市","项目编号":"1-03-16-3700-005","项目名称":"汉济北王墓安全防范工程","保护单位名称":"汉济北王墓","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"83","保护单位类型":"古墓葬","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"烟台市","项目编号":"1-02-16-3700-041","项目名称":"坊子德日建筑群修女楼修缮工程","保护单位名称":"坊子德日建筑群","补助范围":"全国重点文物保护单位保护","补助事项":"维修保护","安排金额（万元）":"283","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"枣庄市","项目编号":"2-02-16-3700-002","项目名称":"大汶口遗址汶河北岸遗址展示系统工程","保护单位名称":"大汶口遗址","补助范围":"大遗址","补助事项":"维修保护","安排金额（万元）":"25","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"枣庄市","项目编号":"5-11-16-3700-007","项目名称":"章丘博物馆馆藏金属文物保护修复（青铜）","保护单位名称":"章丘市博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护修复","安排金额（万元）":"115","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"枣庄市","项目编号":"5-11-16-3700-014","项目名称":"日照市博物馆馆藏金属文物保护修复","保护单位名称":"日照市博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护修复","安排金额（万元）":"202","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"枣庄市","项目编号":"5-11-16-3700-016","项目名称":"济南市章丘博物馆馆藏陶器保护修复","保护单位名称":"章丘市博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护修复","安排金额（万元）":"100","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"枣庄市","项目编号":"3-02-16-3700-002","项目名称":"泰山古建筑群王母池修缮工程","保护单位名称":"泰山古建筑群","补助范围":"世界文化遗产","补助事项":"维修保护","安排金额（万元）":"556","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"直属","项目编号":"2-08-15-3700-008","项目名称":"临淄齐国故城阚家寨冶铸遗址展示及环境整治工程","保护单位名称":"临淄齐国故城","补助范围":"大遗址","补助事项":"环境治理","安排金额（万元）":"1287","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"直属","项目编号":"2-02-16-3700-006","项目名称":"刘公岛甲午战争纪念地——丁汝昌寓所修缮工程","保护单位名称":"刘公岛甲午战争纪念地","补助范围":"大遗址","补助事项":"维修保护","安排金额（万元）":"336","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"直属","项目编号":"2-16-15-3700-010","项目名称":"大汶口遗址汶河北岸区域已发掘房屋遗迹保护性设施工程","保护单位名称":"大汶口遗址","补助范围":"大遗址","补助事项":"维修保护","安排金额（万元）":"716","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"直属","项目编号":"2-01-15-3700-004","项目名称":"临淄齐国故城保护展示专项规划","保护单位名称":"临淄齐国故城","补助范围":"大遗址","补助事项":"保护规划和方案编制","安排金额（万元）":"120","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"直属","项目编号":"2-08-14-3700-001","项目名称":"北城墙西段（孔林二林门至104国道）保护展示及环境整治工程","保护单位名称":"曲阜鲁国故城","补助范围":"大遗址","补助事项":"维修保护","安排金额（万元）":"675","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"直属","项目编号":"5-11-16-3700-004","项目名称":"潍坊市寒亭区文物保管所馆藏金属文物保护修复","保护单位名称":"潍坊市寒亭区文物保管所","补助范围":"可移动文物保护","补助事项":"文物技术保护修复","安排金额（万元）":"260","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"直属","项目编号":"5-11-16-3700-018","项目名称":"济南市章丘博物馆瓷器文物保护修复","保护单位名称":"章丘市博物馆","补助范围":"可移动文物保护","补助事项":"文物技术保护修复","安排金额（万元）":"107","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"直属","项目编号":"1-02-16-3700-043","项目名称":"坊子德日建筑群车站给水所片区修缮工程","保护单位名称":"坊子德日建筑群","补助范围":"全国重点文物保护单位保护","补助事项":"维修保护","安排金额（万元）":"504","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"直属","项目编号":"1-03-16-3700-035","项目名称":"北寨墓群安全防范工程","保护单位名称":"北寨墓群","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"381","保护单位类型":"古墓葬","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"直属","项目编号":"1-03-16-3700-029","项目名称":"丁氏故宅安全防范工程","保护单位名称":"丁氏故宅","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"177","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"直属","项目编号":"1-02-16-3700-040","项目名称":"坊子德日建筑群德日军医院修缮工程","保护单位名称":"坊子德日建筑群","补助范围":"全国重点文物保护单位保护","补助事项":"维修保护","安排金额（万元）":"1008","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"直属","项目编号":"1-03-16-3700-012","项目名称":"嘉祥县青山寺消防系统工程","保护单位名称":"青山寺","补助范围":"全国重点文物保护单位保护","补助事项":"消防","安排金额（万元）":"166","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"直属","项目编号":"1-02-14-3700-112","项目名称":"百寿坊及百狮坊修缮工程","保护单位名称":"百寿坊及百狮坊","补助范围":"全国重点文物保护单位保护","补助事项":"维修保护","安排金额（万元）":"83","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"直属","项目编号":"1-02-16-3700-044","项目名称":"坊子德日建筑群德建军官住宅7号修缮工程","保护单位名称":"坊子德日建筑群","补助范围":"全国重点文物保护单位保护","补助事项":"维修保护","安排金额（万元）":"70","保护单位类型":"近现代","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"直属","项目编号":"1-03-16-3700-026","项目名称":"戚继光祠堂防雷工程","保护单位名称":"戚继光祠堂及戚继光墓","补助范围":"全国重点文物保护单位保护","补助事项":"防雷","安排金额（万元）":"25","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"直属","项目编号":"1-03-16-3700-007","项目名称":"长清莲花洞石窟造像安全防范工程","保护单位名称":"长清莲花洞石窟造像","补助范围":"全国重点文物保护单位保护","补助事项":"安防","安排金额（万元）":"42","保护单位类型":"石窟寺及石刻","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"直属","项目编号":"3-16-15-3700-002","项目名称":"曲阜孔林孔子墓群古建筑修缮工程","保护单位名称":"曲阜孔庙及孔府","补助范围":"世界文化遗产","补助事项":"维修保护","安排金额（万元）":"85","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"直属","项目编号":"3-02-16-3700-004","项目名称":"泰安岱庙天贶殿及炳灵门古建筑油饰彩画保护工程","保护单位名称":"泰山古建筑群","补助范围":"世界文化遗产","补助事项":"维修保护","安排金额（万元）":"557","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"直属","项目编号":"3-02-16-3700-006","项目名称":"曲阜孔庙西路古建筑群修缮工程","保护单位名称":"曲阜孔庙及孔府","补助范围":"世界文化遗产","补助事项":"维修保护","安排金额（万元）":"711","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"直属","项目编号":"3-02-15-3700-013","项目名称":"孔府中、东路古建筑油饰彩画第一期修缮工程","保护单位名称":"曲阜孔庙及孔府","补助范围":"世界文化遗产","补助事项":"维修保护","安排金额（万元）":"20","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"直属","项目编号":"2-02-16-3700-014","项目名称":"大运河遗产临清段临清砖闸、月径桥修缮保护工程","保护单位名称":"大运河","补助范围":"世界文化遗产","补助事项":"维修保护","安排金额（万元）":"414","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"直属","项目编号":"3-02-16-3700-005","项目名称":"曲阜孔庙金声玉振坊至弘道门古建筑群修缮工程","保护单位名称":"曲阜孔庙及孔府","补助范围":"世界文化遗产","补助事项":"维修保护","安排金额（万元）":"329","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"直属","项目编号":"2-16-14-3700-015","项目名称":"大运河遗产会通河临清段会通闸、临清闸保护修缮工程","保护单位名称":"大运河","补助范围":"世界文化遗产","补助事项":"维修保护","安排金额（万元）":"321","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"淄博市","项目编号":"5-10-16-3700-013","项目名称":"东平县博物馆可移动文物预防性保护","保护单位名称":"东平县博物馆","补助范围":"可移动文物保护","补助事项":"预防性保护","安排金额（万元）":"169","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"淄博市","项目编号":"5-11-15-3700-032","项目名称":"曲阜孔府文物档案馆藏书画保护修复","保护单位名称":"曲阜孔府文物档案馆","补助范围":"可移动文物保护","补助事项":"文物技术保护修复","安排金额（万元）":"55","保护单位类型":"","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"淄博市","项目编号":"1-01-16-3700-038","项目名称":"景阳岗遗址保护规划","保护单位名称":"景阳岗遗址","补助范围":"全国重点文物保护单位保护","补助事项":"保护规划和方案编制","安排金额（万元）":"104","保护单位类型":"古遗址","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"淄博市","项目编号":"1-03-16-3700-014","项目名称":"嘉祥县曾庙消防工程","保护单位名称":"曾庙","补助范围":"全国重点文物保护单位保护","补助事项":"消防","安排金额（万元）":"102","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"淄博市","项目编号":"3-02-16-3700-009","项目名称":"孔庙东路古建筑油饰彩画第一期修缮工程","保护单位名称":"曲阜孔庙及孔府","补助范围":"世界文化遗产","补助事项":"维修保护","安排金额（万元）":"445","保护单位类型":"古建筑","项目类别":"重点"},
    {"资金安排年度":"2016","市级":"淄博市","项目编号":"3-02-15-3700-026","项目名称":"孔庙古建筑（大成门、金声玉振门、东西庑）油饰彩画二期保护维修工程","保护单位名称":"曲阜孔庙及孔府","补助范围":"世界文化遗产","补助事项":"维修保护","安排金额（万元）":"39","保护单位类型":"古建筑","项目类别":"重点"},
    
    ]

    export const yearDic = [...new Set(fund.map((p) => p.资金安排年度))];
    export const cityDic = [...new Set(fund.map((p) => p.市级))];
    export const budget_areaDic = [...new Set(fund.map((p) => p.补助范围))];
    export const budget_thingDic = [...new Set(fund.map((p) => p.补助事项))];
    export const typeDic = [...new Set(fund.map((p) => p.项目类别))];